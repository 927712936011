import React, { useEffect, useState } from "react";
import { Table,} from "antd";
import Button from 'react-bootstrap/Button';

const AcceptedSaleAppList = () => {
    const [appointments,setAppointments] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [refresh, setRefresh] = useState();
    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/appointment/getAcceptedApps`);
                const responseData = await response.json();
 
            
            const acceptedAppointments=responseData.acceptedAppointments;
    
            const acceptedRentAppointments = acceptedAppointments.filter((app) => {
                if (!app.propertyId) {
                    return false;
                }
                return app.propertyId.propertyType.includes("sale");
            });
          
                setAppointments(acceptedRentAppointments);
               
            } catch (err) {
                console.log("Error in fetchProperties:", err);
            }
        };
        fetchProperties();
    }, [refresh]);//appointments

    const handleButton = async () => {
    
    
        for (let i = 0; i < selectedRowKeys.length; i++) {
            try {
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/appointment/deleteAcceptedApp?appId=${selectedRowKeys[i]}`);
                const responseData= await response.json();
               
             
                if (!response.ok) {
                    throw new Error("Failed to delete property");
                }
                
             
                
            } catch (err) {
                console.error(err);
            }
        }
    
        if (refresh === true) {
            return setRefresh(false);
        } else {
            return setRefresh(true);
        }
    };

    const dataSource = appointments
    .map(appointment => ({
      key: appointment._id,
      image: appointment.propertyId.coverImgUrl,
      propertyName: appointment.propertyId.propertyName,
      propertyCompound: appointment.propertyId.compound,
      from:appointment.startDate,
      time:appointment.time,
      clientName: `${appointment.userId.fname} ${appointment.userId.lname}`,
      clientEmail: appointment.userId.email,
      clientPhoneNum:appointment.userId.phoneNum
   
    }))
    const columns = [
        {
            title: "Photo",
            dataIndex: "image",
            render: (text) => <img src={text} alt="Property" style={{ width: 50, height: 50 }} />
        },
        {
            title: "Name",
            dataIndex: "propertyName",
        },
        {
            title: "Compound",
            dataIndex: "propertyCompound",
        },{
            title: "Day",
            dataIndex: "from",
            render: (text) => {
                // Convert the text to a Date object
                const date = new Date(text);
                
                // Format the date in YYYY-MM-DD format
                return date.toLocaleDateString('en-US'); // Adjust the locale and options as needed
            }
        },{
            title: "Time",
            dataIndex: "time",
         
        },
        {
            title: "Client Name",
            dataIndex: "clientName",
        },
        {
            title: "Client Email",
            dataIndex: "clientEmail",
        },
        {
            title: "Client Number",
            dataIndex: "clientPhoneNum",
        }, 
       
    ];
    return (
        <div className="p-2 md:p-4">
            {/* Table and Button components */}
            <div className="overflow-x-auto">
                <Table
                    rowSelection={{
                        selectedRowKeys,
                        onChange: setSelectedRowKeys
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    rowKey="key"// Ensure each row has a unique key
                    scroll={{ x: '100%' }} // Enables horizontal scrolling on smaller screens
                />
            </div>
            <div className="flex justify-end">
                <Button onClick={handleButton} className="m-2 md:m-4" variant="outline-primary">
                   Delete 
                </Button>
            </div>
        </div>
    );
    
}
 
export default AcceptedSaleAppList;