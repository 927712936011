import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faBed, faShower, faSwimmer } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RibbonContainer,Ribbon } from "react-ribbons"; 

const Product = (props) => {
  const { property } = props;
  const [t,i18n] = useTranslation("global");
  const navigate = useNavigate();
  console.log(JSON.stringify(property.services));
  let privatePool;
  property.services.forEach((service)=>{
    if(service.enName==="Private Pool"){
      if(i18n.language==='ar')
        privatePool=service.arName
      else if (i18n.language==='de')
        privatePool=service.deName
      else 
      privatePool=service.enName
    }

  })
  const handleViewButton = () => {
    if (property.propertyType === "rent")
      navigate(`/rentPropertyDescription?propertyId=${property._id}`);
    else
      navigate(`/salePropertyDescription?propertyId=${property._id}`);
  };

  return (
    <RibbonContainer >
      {property.discount && (
       <Ribbon 
       side="left"
       type="corner"
       size="large"
       backgroundColor="#D30000"
       color="#FFFFFF"
       fontFamily="serif"
       withStripes={true}
     >
         {t("propCard.offer")}
          </Ribbon>
      )}
      <div className="card">
        <div className="cardImg">
          <img src={property.coverImgUrl} alt="House" className="card-img" />
        </div>
        <div className="card-body">
          <h3 className="card-address">
            <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLocationDot} color="black" />
            {property.location}
          </h3>
          <div className="card-details">
            <div className="card-detail">
              <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faBed} color="black" />
              {property.numBeds} {t("propCard.bedRoom")}
            </div>
            <div className="card-detail">
              <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faShower} color="black" />
              {property.numShower} {t("propCard.BathRoom")}
            </div>
         
            <div className="card-detail">
              <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLocationDot} color="black" />
              {property.compound}
            </div>
            {privatePool &&
            <div className="card-detail">
              <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faSwimmer} color="black" />
              {privatePool}
            </div>
            }
          </div>
          <div className="card-footer">
            <button className="card-button" onClick={handleViewButton}>{t("propCard.btn")}</button>
            <div className="card-price">
              {property.discount ? (
                <>
                  <span style={{ marginLeft: "1em", color: "#d9534f", fontWeight: "bold" }}>
                    {property.discount} EGP
                  </span>
                  <sub style={{ fontWeight: "normal", color: "GrayText", fontSize: '0.75em', textDecoration: 'line-through', marginLeft: '10px' }}>
                    {property.price} 
                  </sub>
                </>
              ) : (
                <span>{property.price} EGP</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </RibbonContainer>
  );
};

export default Product;
