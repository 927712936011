import React, { useState } from 'react';
import {Link,useLocation ,useNavigate} from'react-router-dom'
import {message } from "antd"
import { useTranslation } from 'react-i18next';

const ResetPassForm = ()=>{
    const [t]= useTranslation("global");
    const location = useLocation();
const navigate = useNavigate();
const [loading,setLoading]= useState();
    const submitHandler =async (e) => {
     
        e.preventDefault();
        const formData = new FormData(e.target);
        const formPayload = Object.fromEntries(formData);

        if (formPayload.pass === ''||formPayload.confirmPass==='') {
           
            message.error(t("message.requiredFileds"));
           
        }else if (formPayload.newPass !==formPayload.confirmPass){
            message.error(t("message.passNotMatch"));
        }
        else {
            setLoading(true);
            const params = new URLSearchParams(location.search);
            const tokenFromUrl = params.get('token');
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/resetPass`,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: tokenFromUrl,
                        newPass: formPayload.newPass,
                    }),
                });
                 
    
                const result = await response.json();
                if (result.success) {
                    message.success(result.message);
                    navigate('/');
                } else {
                    message.error(result.message || 'Failed to change password');
                }
                setLoading(false);
            } catch (err) {
                console.error('Error in sending reset link:', err);
                message.error('An error occurred while sending the reset link');
            }

        }

    };
    return  (
            <section className="min-h-screen flex items-center justify-center pt-20 bg-gray-50">
                <div className="w-full max-w-md bg-white rounded-lg shadow border border-gray-200">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                           {t("resetPass.header")}
                        </h1>
    
                        <form onSubmit={submitHandler} className="space-y-4 md:space-y-6" action="#">
                         
                            <div>
                                <label htmlFor="newPass" className="block mb-2 text-sm font-medium text-gray-900">{t("resetPass.newPass")}</label>
                                <input type="password" name="newPass" id="newPass" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="••••••••" required />
                            </div>
                            <div>
                                <label htmlFor="confirmPass" className="block mb-2 text-sm font-medium text-gray-900">{t("resetPass.conPass")}</label>
                                <input type="password" name="confirmPass" id="confirmPass" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="••••••••" required />
                            </div>
                            <button type="submit" className="w-full text-white bg-black focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                {loading ? t("resetPass.btnLoding") : t("resetPass.btn")}
                                </button>
                            <p className="text-sm font-light text-gray-500">
                            {t("resetPass.loginDesc") }
                                <Link to="/login" className="font-medium text-primary-600 hover:underline">{t("resetPass.loginLink") }</Link>
                            </p>
                        </form>
                    </div>
                </div>
            </section>
     );
}

export default ResetPassForm