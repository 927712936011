import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import Button from 'react-bootstrap/Button';
const ManageUsers = () => {
    const [admins, setAdmins] = useState([]);
    const [filteredAdmins, setFilteredAdmins] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/getAdmins`);
                const responseData = await response.json();
                setAdmins(responseData.admins);
                setFilteredAdmins(responseData.admins);
            } catch (err) {
                console.log("Error in fetch Admins:", err);
            }
        };
        fetchAdmins();
    }, [refresh]);

    const handleButton = async () => {
     
    
        for (let i = 0; i < selectedRowKeys.length; i++) {
            try {
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/deleteAdmin?adminId=${selectedRowKeys[i]}`,{
                    method: "delete"
                });
                const responseData= await response.json();
               
             
                if (!response.ok) {
                    throw new Error("Failed to delete admin");
                }
                
             
                
            } catch (err) {
                console.error(err);
            }
        }
    
        if (refresh === true) {
            return setRefresh(false);
        } else {
            return setRefresh(true);
        }
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        const filteredData = admins.filter(admin =>
            `${admin.fname} ${admin.lname}`.toLowerCase().includes(value)
        );
        setFilteredAdmins(filteredData);
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Phone Number",
            dataIndex: "phoneNum",
        }
    ];

    const dataSource = filteredAdmins.map(admin => ({
        key: admin._id,
        name: `${admin.fname} ${admin.lname}`,
        email: admin.email,
        phoneNum:admin.phoneNum
    }));

    return (
        <div className="p-2 md:p-4">
            <Input
                placeholder="Search by name"
                value={searchText}
                onChange={handleSearch}
                style={{ marginBottom: 16 , width :200,borderRadius: 8 }}
            />
            <div className="overflow-x-auto">
                <Table
                    rowSelection={{
                        selectedRowKeys,
                        onChange: setSelectedRowKeys,
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    rowKey="key"
                    scroll={{ x: '100%' }} // Enables horizontal scrolling for smaller screens
                />
            </div>
            <div className="flex justify-end">
                <Button onClick={handleButton} className="m-2 md:m-4" variant="outline-primary">
                   Delete 
                </Button>
            </div>
        </div>
    );
}

export default ManageUsers;
