import React, {useContext, useEffect} from 'react';
import {useNavigate,Link} from'react-router-dom'
import { jwtDecode } from "jwt-decode";
import {message } from "antd"
import { FacebookLoginButton,GoogleLoginButton } from "react-social-login-buttons";
import { useTranslation } from 'react-i18next';
import AuthContext from '../../store/authContext';
import {SignInWithGoogle,SignInWithFacebook } from '../../firebaseConfig/auth';
const LoginForm = () => {
    const [t]= useTranslation("global");
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

    

    const authContext = useContext(AuthContext);
    const navigate = useNavigate();


     const submitHandler = (e) => {
  
        e.preventDefault();
        const formData = new FormData(e.target);
        const formPayload = Object.fromEntries(formData);

        if (formPayload.email === '' || formPayload.pass === '') {
           
            message.error(t("message.requiredFileds"));
           
        }

        
        
   
       else {
       submitData(formPayload);
   
       }

    };
 
    const submitData= async (formPayload)=>{
        const baseUrl = process.env.REACT_APP_BASE_URL;
      
        try{
            const response = await fetch(`${baseUrl}/login`,{
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body:JSON.stringify(formPayload)

            });
            const responseData=await response.json();
             if(responseData.status === false){
                // setStatus(false);
                message.error(t("message.wrongCredential"));
            }else{
                // setStatus(true);
                const decoded = jwtDecode(responseData.jwt);
                authContext.login(decoded.email,decoded.userId,responseData.jwt,decoded.role);
                const { email, userId ,token,role} = decoded;
                if(role==="user"){
                    const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
                    localStorage.removeItem('redirectAfterLogin');
                    navigate(redirectTo);
                }
                else 
                navigate('/adminDashboard');
            }
            }catch(err){
                console.log("error in sending request "+err);
            }
    }

    const loginWithGoogle = async () => {
      const response = await SignInWithGoogle().catch(err => {
        });
        if (response){
        if(response.status === false){
          // setStatus(false);
          message.error(t("message.googleaccNotLinked"));
      }else{
          // setStatus(true);
          const decoded = jwtDecode(response.jwt);
          authContext.login(decoded.email,decoded.userId,response.jwt,decoded.role);
          const { email, userId ,token,role} = decoded;
          if(role==="user"){
              const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
              localStorage.removeItem('redirectAfterLogin');
              navigate(redirectTo);
          }
       
      }}
    };
    const loginWithFacebook =async () => {
     const response= await SignInWithFacebook().catch(err => {
      });
      if(response){
      
      if(response.status === false){
  
        message.error(t("message.FbaccNotLinked"));
    }else{
        
        const decoded = jwtDecode(response.jwt);
        authContext.login(decoded.email,decoded.userId,response.jwt,decoded.role);
        const { email, userId ,token,role} = decoded;
        if(role==="user"){
            const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
            localStorage.removeItem('redirectAfterLogin');
            navigate(redirectTo);
        }
     
    }
  }
  };
    
    

    return (    
        <section className="min-h-screen flex items-center justify-center pt-20 bg-gray-50" style={{marginTop:"6em"}}>
        <div className="w-full max-w-md bg-white rounded-lg shadow border border-gray-200">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              {t("signIn.header")}
            </h1>
  
            <form onSubmit={submitHandler} className="space-y-4 md:space-y-6">
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                  {t("signIn.email")}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="name@company.com"
                  required
                />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                  {t("signIn.pass")}
                </label>
                <input
                  type="password"
                  name="pass"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
              </div>
              <div className="flex items-center justify-between">
                <Link to="/forgotPass" className="text-sm text-primary-600 hover:underline">
                  {t("signIn.forgotPass")}
                </Link>
              </div>
              <button
                type="submit"
                className="w-full text-white bg-black focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {t("signIn.signInBtn")}
              </button>
              <p className="text-sm font-light text-gray-500">
                {t("signIn.signUpDes")}
                <Link to="/signUP" className="font-medium text-primary-600 hover:underline">
                  {t("signIn.signUplink")}
                </Link>
              </p>
            </form>
  
            {/* Social Sign-In Buttons */}
            <div className="mt-6">
      {/* Horizontal line with 'or' */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <hr style={{ flex: 1 }} />
        <span style={{ padding: '0 10px' }}>or</span>
        <hr style={{ flex: 1 }} />
      </div>

      <a
      onClick={loginWithGoogle}
      data-provider="Google"
      data-social-button-provider="google"
      data-social-button="true"
      data-social-button-type=""
      data-social-white-background="true"
      data-id="EmailPage-GoogleSignInButton"
      role="button"
      tabIndex="0"
      className="spectrum-ActionButton flex items-center justify-center rounded-full border border-gray-300 bg-white text-gray-700 font-medium transition-all px-6 py-3 hover:shadow-md hover:bg-gray-100"
      style={{ textDecoration: 'none' }}
   >
      <svg
        viewBox="0 0 1152 1152"
        focusable="false"
        aria-hidden="true"
        role="img"
        className="spectrum-Icon spectrum-Icon--sizeS w-5 h-5 mr-3"
        data-social-button-type="icon"
        data-social-white-background="true"
      
      >
        <path
          d="M1055.994 594.42a559.973 559.973 0 0 0-8.86-99.684h-458.99V683.25h262.28c-11.298 60.918-45.633 112.532-97.248 147.089v122.279h157.501c92.152-84.842 145.317-209.78 145.317-358.198z"
          fill="#4285f4"
        ></path>
        <path
          d="M588.144 1070.688c131.583 0 241.9-43.64 322.533-118.07l-157.5-122.28c-43.64 29.241-99.463 46.52-165.033 46.52-126.931 0-234.368-85.728-272.691-200.919H152.636v126.267c80.19 159.273 245 268.482 435.508 268.482z"
          fill="#34a853"
        ></path>
        <path
          d="M315.453 675.94a288.113 288.113 0 0 1 0-185.191V364.482H152.636a487.96 487.96 0 0 0 0 437.724z"
          fill="#fbbc05"
        ></path>
        <path
          d="M588.144 289.83c71.551 0 135.792 24.589 186.298 72.88l139.78-139.779C829.821 144.291 719.504 96 588.143 96c-190.507 0-355.318 109.21-435.508 268.482L315.453 490.75c38.323-115.19 145.76-200.919 272.691-200.919z"
          fill="#ea4335"
        ></path>
      </svg>
      <span className="spectrum-ActionButton-label">Continue with Google</span>
    </a>

    <a
    onClick={loginWithFacebook}
      data-provider="Facebook"
      data-social-button-provider="facebook"
      data-social-button="true"
      data-social-button-type=""
      data-social-white-background="true"
      data-id="EmailPage-FacebookSignInButton"
      role="button"
      tabIndex="0"
      className="mt-3 spectrum-ActionButton flex items-center justify-center rounded-full border border-gray-300 bg-white text-gray-700 font-medium transition-all px-6 py-3 hover:shadow-md hover:bg-gray-100"
      style={{ textDecoration: 'none' }}
    >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        focusable="false"
        aria-hidden="true"
        role="img"
        className="spectrum-Icon spectrum-Icon--sizeS w-5 h-5 mr-3"
        data-social-button-type="icon"
        data-social-white-background="true"
      >
        <path
          fill="#1877f2"
          d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"
        />
      </svg>
      <span className="spectrum-ActionButton-label">Continue with Facebook</span>
    </a>
        {/* <GoogleLoginButton
          onClick={loginWithGoogle}
        />
        <FacebookLoginButton
          onClick={loginWithFacebook}
        /> */}
     
    </div>
          </div>
        </div>
      </section>
      
    );
}

export default LoginForm;
