import React from 'react';

const TermsOfService = () => {
    return (
        <div className="customMargin container mx-auto p-5 border border-gray-300 rounded-lg shadow-md">
            <h1 className="text-center text-2xl font-bold mb-4">Terms and Conditions</h1>
            <p><strong>Effective Date:</strong> 10/5/2024</p>

            <p>
                Welcome to <strong>EL Gouna Luxury™</strong> ("we," "us," or "our"). By accessing or using our website <a href="https://luxuryelgounarealestate.com" target="_blank" rel="noopener noreferrer">www.luxuryelgounarealestate.com</a> (the "Site") and any related services (collectively, the "Services"), you agree to be bound by these Terms and Conditions.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">1. Acceptance of Terms</h2>
            <p>
                By using our Services, you confirm that you accept these Terms and Conditions and that you agree to comply with them. If you do not agree to these terms, you must not use our Services.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">2. Changes to Terms</h2>
            <p>
                We may revise these Terms from time to time. Changes will be posted on this page, and your continued use of our Services after such changes constitutes your acceptance of the new Terms.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">3. User Responsibilities</h2>
            <p>
                You agree to use our Services only for lawful purposes and in a way that does not infringe the rights of anyone else. You are responsible for ensuring that any information you provide is accurate and complete.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">4. Intellectual Property</h2>
            <p>
                All content, trademarks, and other intellectual property on our Site are owned by or licensed to <strong>EL Gouna Luxury™</strong>. You are granted a limited, non-exclusive, non-transferable license to use the Services for personal, non-commercial use.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">5. Limitation of Liability</h2>
            <p>
                To the fullest extent permitted by law, we exclude all conditions, warranties, representations, or other terms that may apply to our Services or any content on it. We will not be liable for any loss or damage that is not foreseeable.
            </p>

        
            <h2 className="text-xl font-semibold mt-6 mb-2">6. Contact Us</h2>
            <p>
                If you have any questions about these Terms, please contact us at:
            </p>
            <p>
                <strong>Email:</strong> <a href="mailto:support@luxuryelgounarealestate.com">support@luxuryelgounarealestate.com</a><br />
                <strong>Phone:</strong> 01023100148<br />
            
            </p>
        </div>
    );
};

export default TermsOfService;
