import React from 'react';
import PhotoGrid from '../photoGrid/photoGrid';
import 'flowbite/dist/flowbite.min.css';
const carousel = () => {
  const images = [
    'https://picsum.photos/800/600?random=1',  // Large image
    'https://picsum.photos/800/300?random=2',  // Small image 1
    'https://picsum.photos/400/300?random=3',  // Small image 2
    'https://picsum.photos/400/300?random=4',  // Small image 3
    'https://picsum.photos/400/300?random=5'   // Small image 4
  ];
  return (
    <>
   <div className='customMargin'>
      <h1 className="text-center">Photo Gallery</h1>
      <div className='d-flex justify-content-center' style={{ maxWidth: "1120px", margin: "0 auto" }}>
        <PhotoGrid imgUrls={images} />
      </div>
    </div>
    </>
  );
}

export default carousel;
