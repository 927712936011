import React, { useState,useEffect } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import {message} from "antd";
import { useTranslation } from 'react-i18next';
import { FacebookLoginButton,GoogleLoginButton } from "react-social-login-buttons";
import {SignUpWithGoogle,SignUpWithFacebook } from '../../firebaseConfig/auth';



const SignUpForm = () => {
    const [validationErrors, setValidationErrors] = useState({});
    // const [userExistErr,setUserExistErr] = useState();
     const nagvigate = useNavigate();
     const [t]= useTranslation("global");
     useEffect(()=>{
        window.scrollTo(0, 0);
      },[])
    const submitHandler = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const formPayload = Object.fromEntries(formData.entries());
        formPayload.role = 'user'; 
       

        const errors = validateForm(formPayload);
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        setValidationErrors({});
       submitForm(formPayload);
    };

    const validateForm = (formData) => {
        const errors = {};

        if (!formData.fname || formData.fname.trim() === '') {
            errors.fname = 'First Name is required';
        }

        if (!formData.lname || formData.lname.trim() === '') {
            errors.lname = 'Last Name is required';
        }

        if (!formData.pass || formData.pass.length < 5) {
            errors.pass = 'Password must be at least 5 characters long';
        }

        if (formData.pass !== formData.confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        const phoneRegex = /^[0-9]{11}$/; 
        if (!phoneRegex.test(formData.phoneNum)) {
            errors.phoneNum = 'Please insert a valid phone number';
        }

        return errors;
    };
const submitForm= async (formPayload)=>{
try{    

    const baseUrl =process.env.REACT_APP_BASE_URL;
    const response = await fetch(`${baseUrl}/signUp`,{
        method: 'POST',
        headers:{
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formPayload)
    });
    const responseData = await response.json();
    

    if(responseData.response==="User already exists"){
        message.error(t("message.userExit"));
    }else {
        nagvigate('/logIn');
    }


}catch(err){
    console.log("error in sending request to the server : "+err);
}

}
const GoogleSignUp =async () => {
   const response= await SignUpWithGoogle().catch(err => {
        console.log("error in sending google login request " + err);
    });
    if(response){
    if(response==="User already exists"){
        message.error(response);
    }
    else {
        message.success("Account created successfully, please login");
    nagvigate('/login')
    }
}

};
const FacebookSignUp = async () => {
  const response =await SignUpWithFacebook().catch(err => {
      console.log("error in sending facebook login request " + err);
  });
  if(response){
  if(response==="User already exists"){
    message.error(response);
}
else {
    message.success("Account created successfully, please login");
nagvigate('/login')
}
  }

};
    return (
        <section className="customMargin bg-gray-50 dark:bg-gray-900 min-h-screen flex items-center justify-center p-4">
            <div className="w-full max-w-md bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        {t("singUp.header")}
                    </h1>
                    <form onSubmit={submitHandler} className="space-y-4 md:space-y-6">
                        {/* {userExistErr &&  <div className="alert alert-danger text-center container-fluid" role="alert">{userExistErr}</div>} */}
                        <div className="flex flex-col md:flex-row md:space-x-4">
                            <div className="w-full md:w-1/2">
                                {validationErrors.fname && <div className="text-red-500">{validationErrors.fname}</div>}
                                <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("singUp.fname")}</label>
                                <input type="text" name="fname" id="first-name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
                            </div>
                            <div className="w-full md:w-1/2">
                                {validationErrors.lname && <div className="text-red-500">{validationErrors.lname}</div>}
                                <label htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("singUp.lname")}</label>
                                <input type="text" name="lname" id="last-name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Doe" required />
                            </div>
                        </div>
                        <div>
                            {validationErrors.email && <div className="text-red-500">{validationErrors.email}</div>}
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("singUp.email")}</label>
                            <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required />
                        </div>
                        <div>
                            {validationErrors.phoneNum && <div className="text-red-500">{validationErrors.phoneNum}</div>}
                            <label htmlFor="phoneNum" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("singUp.phoneNum")}</label>
                            <input type="text" name="phoneNum" id="phoneNum" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="01*********" required />
                        </div>
                        <div>
                            {validationErrors.pass && <div className="text-red-500">{validationErrors.pass}</div>}
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("singUp.pass")}</label>
                            <input type="password" name="pass" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                        </div>
                        <div>
                            {validationErrors.confirmPassword && <div className="text-red-500">{validationErrors.confirmPassword}</div>}
                            <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("singUp.confPass")}</label>
                            <input type="password" name="confirmPassword" id="confirm-password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                        </div>
                        <button type="submit" className="w-full text-white bg-black focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">{t("singUp.singUpBtn")}</button>
                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                        {t("singUp.loginDesc")} <Link to="/logIn" className="font-medium text-primary-600 hover:underline dark:text-primary-500">{t("singUp.loginLink")}</Link>
                        </p>
                    </form>
                    <div className="mt-6">
      {/* Horizontal line with 'or' */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <hr style={{ flex: 1 }} />
        <span style={{ padding: '0 10px' }}>or</span>
        <hr style={{ flex: 1 }} />
      </div>

      <a
      onClick={GoogleSignUp}
      data-provider="Google"
      data-social-button-provider="google"
      data-social-button="true"
      data-social-button-type=""
      data-social-white-background="true"
      data-id="EmailPage-GoogleSignInButton"
      role="button"
      tabIndex="0"
      className="spectrum-ActionButton flex items-center justify-center rounded-full border border-gray-300 bg-white text-gray-700 font-medium transition-all px-6 py-3 hover:shadow-md hover:bg-gray-100"
      style={{ textDecoration: 'none' }}
   >
      <svg
        viewBox="0 0 1152 1152"
        focusable="false"
        aria-hidden="true"
        role="img"
        className="spectrum-Icon spectrum-Icon--sizeS w-5 h-5 mr-3"
        data-social-button-type="icon"
        data-social-white-background="true"
      
      >
        <path
          d="M1055.994 594.42a559.973 559.973 0 0 0-8.86-99.684h-458.99V683.25h262.28c-11.298 60.918-45.633 112.532-97.248 147.089v122.279h157.501c92.152-84.842 145.317-209.78 145.317-358.198z"
          fill="#4285f4"
        ></path>
        <path
          d="M588.144 1070.688c131.583 0 241.9-43.64 322.533-118.07l-157.5-122.28c-43.64 29.241-99.463 46.52-165.033 46.52-126.931 0-234.368-85.728-272.691-200.919H152.636v126.267c80.19 159.273 245 268.482 435.508 268.482z"
          fill="#34a853"
        ></path>
        <path
          d="M315.453 675.94a288.113 288.113 0 0 1 0-185.191V364.482H152.636a487.96 487.96 0 0 0 0 437.724z"
          fill="#fbbc05"
        ></path>
        <path
          d="M588.144 289.83c71.551 0 135.792 24.589 186.298 72.88l139.78-139.779C829.821 144.291 719.504 96 588.143 96c-190.507 0-355.318 109.21-435.508 268.482L315.453 490.75c38.323-115.19 145.76-200.919 272.691-200.919z"
          fill="#ea4335"
        ></path>
      </svg>
      <span className="spectrum-ActionButton-label">Continue with Google</span>
    </a>

    <a
    onClick={FacebookSignUp}
      data-provider="Facebook"
      data-social-button-provider="facebook"
      data-social-button="true"
      data-social-button-type=""
      data-social-white-background="true"
      data-id="EmailPage-FacebookSignInButton"
      role="button"
      tabIndex="0"
      className="mt-3 spectrum-ActionButton flex items-center justify-center rounded-full border border-gray-300 bg-white text-gray-700 font-medium transition-all px-6 py-3 hover:shadow-md hover:bg-gray-100"
      style={{ textDecoration: 'none' }}
    >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        focusable="false"
        aria-hidden="true"
        role="img"
        className="spectrum-Icon spectrum-Icon--sizeS w-5 h-5 mr-3"
        data-social-button-type="icon"
        data-social-white-background="true"
      >
        <path
          fill="#1877f2"
          d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"
        />
      </svg>
      <span className="spectrum-ActionButton-label">Continue with Facebook</span>
    </a>
        {/* <GoogleLoginButton
          onClick={GoogleSignUp}
        />
        <FacebookLoginButton
          onClick={FacebookSignUp}
        /> */}
     
    </div>
                </div>
            </div>
        </section>
    );
}

export default SignUpForm;
