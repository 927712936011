import { auth } from "./firebaseconfig";
import { FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export const SignUpWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
        'hl': navigator.language || navigator.userLanguage
    });
    try {
        const result = await signInWithPopup(auth, provider);
   
        const {providerId}= result;
        let {phoneNumber}= result.user;
        if(phoneNumber===null)
            phoneNumber="N/A";
        const userInfo = {
            fname:result._tokenResponse.firstName,
            lname:result._tokenResponse.lastName,
            email:result._tokenResponse.email,
            uid : result.user.uid,
            provider : providerId,
            phoneNumber:phoneNumber,
            role:"user"
        }
       return saveUser(userInfo);
    
      
    } catch (err) {
        console.error("Error during Google sign-in: ", err);
      
    }
};

export const SignUpWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    provider.setCustomParameters({
        'hl': navigator.language || navigator.userLanguage
    });
    try {
        const result = await signInWithPopup(auth, provider);
    
        const user = result.user;
        const providerId = user.providerData[0].providerId; 
        const uid = user.uid; 
        let phoneNum= user.phoneNumber;
        if(phoneNum===null)
            phoneNum="N/A";

        
        const userInfo = {
            fname:result._tokenResponse.firstName,
            lname:result._tokenResponse.lastName,
            email:result._tokenResponse.email,
            uid : uid,
            provider : providerId,
            phoneNumber:phoneNum,
            role:"user"
        }
        return saveUser(userInfo);
    
   
    } catch (err) {
        console.error("Error during Google sign-in: ", err);
      
    }
};

const saveUser= async (userInfo)=>{
    try{    
    
        const baseUrl =process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${baseUrl}/signUpSocials`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userInfo)
        });
        const responseData = await response.json();
    
          return responseData.response;

    
    }catch(err){
        console.log("error in sending request to the server : "+err);
    }
    
    }

    const login= async(userInfo)=>{
        try{
            const baseUrl =process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${baseUrl}/signInSocials`,{
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userInfo)
            });
            const responseData = await response.json();
            return responseData;

        }catch(err){
            console.log("error in sending login request to the server : "+err);
        }
    }


    export const SignInWithGoogle = async()=>{
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            'hl': navigator.language || navigator.userLanguage
        });
        try{
            const result = await signInWithPopup(auth, provider);
            if(result){
            const userData= {
                email:result._tokenResponse.email,
                uid : result.user.uid,
            }

          return login(userData);
        }
        }catch(err){
            console.log("error in sending request to google API : "+err);
        }
    }

    export const SignInWithFacebook = async()=>{
        const provider = new FacebookAuthProvider();
        provider.setCustomParameters({
            'hl': navigator.language || navigator.userLanguage
        });
        try{
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const userData = {
                 uid: user.uid,
                email:result._tokenResponse.email
            }
           return login(userData);
        }catch(err){
            console.log("error in sending request to google API : "+err);
        }
    }
