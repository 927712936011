import React, { useEffect, useState ,useContext} from 'react';
import '../App.css'
import AuthContext from '../store/authContext';
import 'flowbite/dist/flowbite.min.css';
import { useLocation,useNavigate } from 'react-router-dom';
import {message} from 'antd';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import { FourSquare } from "react-loading-indicators";
import emailjs from '@emailjs/browser';
import Carousel from '../component/carousel/carousel2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBed,
  faShower,
  faEye,
  faLocationDot,
  faRuler,
  faUtensils,
  faParking,
  faTshirt,
  faWifi,
  faTv,
  faSnowflake,
  faExclamationTriangle,
  faFire,
  faBold,
  faBowlFood,
  faUserTie,
  faWaterLadder,
  faHotTub,
  faUmbrella,
  faTable,
  faBaseball,
  faHouseFire,
  faMusic,
  faDumbbell,
  faWater,
  faUmbrellaBeach,
  faSwimmer
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PhotoGrid from '../component/photoGrid/photoGrid';
import RentModal from '../component/appointment/rentModal';

const PropertyDetailsPage = (props) => {
    const navigate = useNavigate();
    const [property, setProperty] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const propertyId = queryParams.get('propertyId');
    const [isLoading,setIsLoading]=useState(true);

    const [startDate,setStartDate]=useState();
    const [endDate,setEndDate]=useState();

    const [propertyPrice,setPropertyPrice]=useState();
    const [totalPrice,setTotalPrice]=useState();
    const [isModalOpen, setModalOpen] = useState(false);
    const [startDates, setStartDates]=useState([]);
    const [endDates, setEndDates]=useState([]);
    const [disabledDays, setDisabledDays]=useState([]);
    // const [isMounted, setIsMounted] = useState(false);
    const [t,i18n]= useTranslation("global");
    const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
    const[propertyPackage,setPropertyPackage]=useState([]); 
    const [packageDays, setPackageDays] = useState([]);
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [NOSelectedDaysNotInPackage, setNOSelectedDaysNotInPackage] = useState();
    const [NumberOfNights,setNumberOfNights]= useState();
    const [services,setServices]=useState([]);
    
    const iconMapping = {
      faBed: faBed,
      faShower: faShower,
      faEye: faEye,
      faLocationDot: faLocationDot,
      faRuler: faRuler,
      faUtensils: faUtensils,
      faParking: faParking,
      faTshirt: faTshirt,
      faWifi: faWifi,
      faTv: faTv,
      faSnowflake: faSnowflake,
      faExclamationTriangle: faExclamationTriangle,
      faFire: faFire,
      faBold: faBold,
      faBowlFood: faBowlFood,
      faUserTie: faUserTie,
      faWaterLadder: faWaterLadder,
      faHotTub: faHotTub,
      faUmbrella: faUmbrella,
      faTable: faTable,
      faBaseball: faBaseball,
      faHouseFire:faHouseFire,
      faMusic: faMusic,
      faDumbbell: faDumbbell,
      faWater: faWater,
      faUmbrellaBeach: faUmbrellaBeach,
      faSwimmer:faSwimmer
  };
    const openModal = () => {

        setModalOpen(true);
    }
    const closeModal = () => setModalOpen(false);

    const handleFormSubmit = (e) => {
        e.preventDefault(); 
       const partialPackage= handleCalender();
       if(selectedDayRange.from ===null || selectedDayRange.to === null){
        message.error("Please select a start and end date");
        return;
       }
      const startDate=convertToLocalizedDate(selectedDayRange.from);
      const endDate=convertToLocalizedDate(selectedDayRange.to);
      setDates(startDate,endDate); 
       const startDateObject = new Date(startDate).setHours(0, 0, 0, 0); // Set start date to midnight
      const currentDateObject = new Date(); // Get the current date
      currentDateObject.setHours(0, 0, 0, 0); // Set current date to midnight
        if (startDateObject < currentDateObject) {
            const dateError = t("message.dateErr"); 
            message.error(dateError); 
        }
      else{
          const start = new Date(startDate);
           const end = new Date(endDate);

          const oneDay = 24 * 60 * 60 * 1000; 
          const numberOfNights = Math.round((end - start) / oneDay)+1;
          setNumberOfNights(numberOfNights)
          if(property.discount){
            setTotalPrice((numberOfNights+1) * property.discount);
     
          }
          else{
          setTotalPrice((numberOfNights+1) * propertyPrice);
          }
          if(partialPackage){
            message.error(t("message.partialPackageError"));
           }else{
           openModal();
           } 
        }
    };
 
const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 1; 
const day = today.getDate();


const defaultFrom = {
  year: year,
  month: month,
  day: day,
};


const defaultTo = {
  year: year,
  month: month,
  day: day + 2, 
};


const defaultValue = {
  from: defaultFrom,
  to: defaultTo,
};

    const [selectedDayRange, setSelectedDayRange] = useState(
      defaultValue
    );
    const convertToLocalizedDate = (dateObj) => {
      const { day, month, year } = dateObj;
      // const date = new Date(year, month - 1, day+1); 
      const date = new Date(Date.UTC(year, month - 1, day));
     return  date.toISOString();
    };

    const setDates=(startDate,endDate)=>{
      setStartDate(startDate);
      setEndDate(endDate);

    }

    const bookApp = async()=>{
            let status; 
            let bookedBy;
            let packagesNames=[];
            let packagesPrices=[];
       
            if (!sessionStorage.getItem('token')) {
                localStorage.setItem('redirectAfterLogin', window.location.pathname+window.location.search);
                navigate('/logIn');
                return;
            }else if (sessionStorage.getItem('role')=== 'admin'){
                status="accepted";
             
            }else{
                status="pending";
            }
            let propertyPrice ;
            let noOfNightsOutsidePackage;
            if(property.discount){
              propertyPrice=property.discount;
            }
            else {
              propertyPrice=property.price;
            }
            if(selectedPackages.length>0){
              selectedPackages.forEach((pkg)=>{
                packagesNames.push(pkg.packageName);
                packagesPrices.push(pkg.packagePrice);
              })
              noOfNightsOutsidePackage=NOSelectedDaysNotInPackage;
            }
              let totalPackagesPrice=0;
              let totalPrice=0;
              if (selectedPackages.length>0) {
            for (let i = 0; i < selectedPackages.length; i++) {
              totalPackagesPrice += selectedPackages[i].packagePrice;
            }
            totalPrice=(property.price*noOfNightsOutsidePackage) + totalPackagesPrice
         
          }else {
            noOfNightsOutsidePackage=NumberOfNights;
            totalPrice=(propertyPrice*noOfNightsOutsidePackage) ;
         
            
          }
          
            
            const userId=sessionStorage.getItem('userId');
            const appData={
                userId:userId,
                startDate:startDate,
                endDate:endDate,
                propertyId:property._id,
                bookedBy:sessionStorage.getItem('role'),
                status:status,
                packageNames:packagesNames,
                packagePrices:packagesPrices,
                nightsOutsidePackages:noOfNightsOutsidePackage,
                total:totalPrice 
            }
            try{
                
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/appointment/bookApp`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(appData)
                });
                if(!response.ok){
                    console.log("error in response");
                }
                const resposeData= await response.json();
            
                if(resposeData.app.mesg==="appointment booked successfully"){
                    message.success(t("message.appSucess"));
                    setTimeout(()=>{
                        sendEmail();
                        navigate("/");
                    },2000);
                }
                else if (resposeData.app.mesg==="This property is already booked for the selected dates")
                    message.error(t("message.propertyExist"));
            }catch(err){
                console.log("error in adding appointment : "+err)
            }

    }
    const sendEmail = async()=>{
        try{
            const baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${baseUrl}/getUserData?userId=${sessionStorage.getItem('userId')}`);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json(); 
            const email = data.userData.email;
            const name = data.userData.name;
            let propertyPrice ;
            if(property.discount){
              propertyPrice=property.discount;
            }
            else {
              propertyPrice=property.price;
            }
        
            if(selectedPackages.length>0){
              let packagesInfo = '';
              let totalPrice = 0;
            selectedPackages.forEach((pkg) => {
              const packageName = pkg.packageName;
              const packagePrice = pkg.packagePrice;
              totalPrice += packagePrice; 

    packagesInfo += `
      - Package Name: ${packageName}
      - Start Date: ${pkg.startDate} 
      - End Date: ${pkg.endDate} 
    `;
  });


  const noOfDaysOutsidePackage = NOSelectedDaysNotInPackage;
  const extraDaysPrice = propertyPrice * noOfDaysOutsidePackage;
  totalPrice += extraDaysPrice;

  const message = `
I hope this message finds you well.
Thank you for choosing Luxuryelgounarealestate for your property needs. To confirm your reservation for the following property, please proceed with a payment of  ${totalPrice} EGP through Instapay at your earliest convenience.
  
Property Details:
  - Apartment Name: ${property.propertyName}
  - Location: ${property.location}
  - Start Date: ${startDate}
  - End Date: ${endDate}
  - Price Per Night (Outside Package): ${propertyPrice} EGP

  Packages Details:
  ${packagesInfo}
  - Total Booked Days Outside Packages: ${noOfDaysOutsidePackage}
  - Additional Price for Days Outside Package: ${extraDaysPrice} EGP
  - Total Price: ${totalPrice} EGP

 You can complete the payment using the Instapay link below:

https://ipn.eg/S/abdallahabnody/instapay/8TDKaC

Upon successful payment, a confirmation email will be sent to you.
Should you have any questions or require assistance with the payment process, please do not hesitate to contact us at 01023100148 or visit our website at www.luxuryelgounarealestate.com
Thank you for your prompt attention to this matter.
  `;
  
  emailjs
  .send(
    'service_jvmwwqj', // paste your ServiceID here (you'll get one when your service is created).
    'template_v39w1xb', // paste your TemplateID here (you'll find it under email templates).
    {
      subject:"Payment Confirmation Required for Your elGonaLuxury Reservation",
      to_name: name,// put your name here.
      to_email: email,
      message: message,
      
   

    },
    '7CHJ5K3dA2WKCthuj' //paste your Public Key here. You'll get it in your profile section.
  )

          
            }
            else{
            emailjs
            .send(
              'service_jvmwwqj', // paste your ServiceID here (you'll get one when your service is created).
              'template_47yr1q6', // paste your TemplateID here (you'll find it under email templates).
              {
                toName: name,// put your name here.
                to_email: email,
                propertyName:property.propertyName,
                Location:property.location,
                startDate:startDate,
                endDate:endDate,
                pricePerNight:propertyPrice,
                totalPrice:totalPrice,
             
          
              },
              '7CHJ5K3dA2WKCthuj' //paste your Public Key here. You'll get it in your profile section.
            )}
        }catch(err){

        }
       
        
    
    }
    
    useEffect(()=>{
       window.scrollTo(0, 0);

        const fetchPropertyData=async ()=>{
            try{
         
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/property/getPropertyUsingId?propertyId=${propertyId}`);
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json(); 
                setProperty(data.property);
                setServices(data.property.services);
             
            }catch(err){
                console.log("error in fetching property data : "+err);
            }
        }
        const fetchDisableDates= async ()=>{
            try{
             
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/appointment/getDisableDates?appId=${propertyId}`);
            
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();

                setStartDates(responseData.disabledDates.startDates);  
                setEndDates(responseData.disabledDates.endDates);
                constructDisabledDates(responseData.disabledDates.startDates,responseData.disabledDates.endDates);
            }catch(err){
            console.log("error in fetching disable dates : "+err );
            }
        }
        
        const fetchPropertyPrice = async()=>{
            try{
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/property/getPropPrice?propertyId=${propertyId}`);
                const responseData = await response.json();
             
                if(response){
                    setPropertyPrice(responseData.propertyPrice);
                }
            }catch(err){
                console.log("error in fetching property price "+err );
            }
        }
        const fetchPackages= async()=>{
          try{
              
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/package/getPropertyPackages?propId=${propertyId}`);
                const responseData = await response.json();
             
                if(response){
                  setPropertyPackage(responseData.response);
                  constructPackageDays(responseData.response);
                }
            
                setIsLoading(false);
          }catch(err){
            console.log("error in fetchin package "+err);
          }
        }
        fetchPropertyData();
        fetchPropertyPrice();
        fetchDisableDates();
        fetchPackages();
    },[]);

    // useEffect(() => {
    //   // Assuming you have logic that sets isMounted elsewhere
    //   setIsMounted(true);
    
    //   return () => {
    //     setIsMounted(false); // Set before cleanup to prevent listener removal on null elements
    //   };
    // }, []);
    
    




    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { 
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          timeZone: 'UTC',
      });
  };
  
  
  


const isDateDisabled = (date) => {
  const inputDate = new Date(date);
  for (let i = 0; i < startDates.length; i++) {
  
    const startDate = new Date(startDates[i]);
    const endDate = new Date(endDates[i]);
    const endDatePlusOneDay = new Date(endDate);
    endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);

    if (inputDate >= startDate && inputDate <= endDatePlusOneDay) {
      return true;
    }
  }
  
  return false;
};

const constructDisabledDates = (stDate, enDates) => {
  let disabledDates = [];

  const addDatesInRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    
    // Loop from start date to end date (inclusive)
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      disabledDates.push({
        year: date.getFullYear(),
        month: date.getMonth() + 1, // Months are 0-indexed in JavaScript
        day: date.getDate(),
      });
    }
  };

  // Construct disabled dates based on the start and end dates
  for (let i = 0; i < stDate.length; i++) {
    addDatesInRange(stDate[i], enDates[i]);
  }

  setDisabledDays(disabledDates);
};
const constructPackageDays = (pckgs) => {
  let packageDays = [];
  pckgs.forEach((pckg)=>{
    const startDate = new Date(pckg.startDate);
    const endDate = new Date(pckg.endDate);
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      packageDays.push({
        year: date.getFullYear(),
        month: date.getMonth() + 1, 
        day: date.getDate(),
        className:"redDay",
        packageId: pckg._id,
        packageName: pckg.packageName,
        packagePrice: pckg.packagePrice,
      

      });
    }

    


  })
    
 
     

  setPackageDays(packageDays);

  };

  const handleCalender = () => {
    setSelectedPackages([]);

    if (selectedDayRange.from === null || selectedDayRange.to === null) {
      return;
    }
  
    const selectedStart = convertToDateObject(selectedDayRange.from);
    const selectedEnd = convertToDateObject(selectedDayRange.to);
    selectedStart.setHours(0, 0, 0, 0); 
    selectedEnd.setHours(0, 0, 0, 0); 
  
    let packageRanges = [];
    let isFullPackageFound = false;
    let isPartialPackageFound = false;
    let twoPackagesFlag = false; // Flag to check for two packages
  
    propertyPackage.forEach((pkg) => {
      const packageStart = new Date(pkg.startDate);
      const packageEnd = new Date(pkg.endDate);
      packageStart.setHours(0, 0, 0, 0);
      packageEnd.setHours(0, 0, 0, 0);
  
      const isPackageFullyIncluded = (
        selectedStart <= packageStart && 
        selectedEnd >= packageEnd
      );
  
      const isPackagePartiallyIncluded = (
        selectedStart <= packageEnd && selectedEnd >= packageStart
      );
  
      if (isPackageFullyIncluded || isPackagePartiallyIncluded) {
        packageRanges.push({ start: packageStart, end: packageEnd });
        setSelectedPackages((prevPackages) => [...prevPackages, pkg]);
        if (isPackageFullyIncluded) {
          isFullPackageFound = true;
        } else {
          isPartialPackageFound = true;
        }
      }
    });
  
    if (packageRanges.length === 0) {
      return;
    }
  
    // Set the flag for two packages
    if (packageRanges.length > 1) {
      twoPackagesFlag = true;
    }
  
    packageRanges.sort((a, b) => a.start - b.start); // Sort by start date
  
    // Merge overlapping or adjacent ranges
    let mergedRanges = [packageRanges[0]];
    for (let i = 1; i < packageRanges.length; i++) {
      let lastRange = mergedRanges[mergedRanges.length - 1];
      let currentRange = packageRanges[i];
  
      // Check if packages overlap or are adjacent (no gap between them)
      if (currentRange.start <= lastRange.end || 
          (lastRange.end.getTime() + (1000 * 60 * 60 * 24)) >= currentRange.start.getTime()) {
        // Merge ranges by extending the last range's end
        lastRange.end = new Date(Math.max(lastRange.end, currentRange.end));
      } else {
        // If no overlap or adjacency, add as a new range
        mergedRanges.push(currentRange);
      }
    }
  
    const calculateNights = (start, end) => {
      return Math.max(0, Math.floor((end - start) / (1000 * 60 * 60 * 24))); // Correct night calculation
    };
  
    let daysOutsidePackages = 0;
  
    // Calculate days before the first merged package
    if (selectedStart < mergedRanges[0].start) {
      daysOutsidePackages += calculateNights(selectedStart, mergedRanges[0].start);
    }
  
    // Calculate days after the last merged package
    if (selectedEnd > mergedRanges[mergedRanges.length - 1].end) {
      daysOutsidePackages += calculateNights(mergedRanges[mergedRanges.length - 1].end, selectedEnd);
    }
  
    // Calculate the gap days between merged package ranges
    for (let i = 0; i < mergedRanges.length - 1; i++) {
      // Calculate the gap between the end of the current range and the start of the next
      const gapNights = calculateNights(mergedRanges[i].end, mergedRanges[i + 1].start);
      // Only add gap nights if there is an actual gap
      if (gapNights > 0) {
        daysOutsidePackages += gapNights; // Add the gap days correctly
      }
    }
  
    // If two packages are found, subtract one day from outside packages
    if (twoPackagesFlag) {
      daysOutsidePackages -= 1;
    }
  
    setNOSelectedDaysNotInPackage(daysOutsidePackages);
  
     if (isPartialPackageFound) {
      return true;
    }
    if (isFullPackageFound && isPartialPackageFound && twoPackagesFlag) {
      return true; 
  }
  };
  
  
  
  
  
  
  
  

  
  

  const convertToDateObject = (dateObj) => {
    return new Date(dateObj.year, dateObj.month - 1, dateObj.day);
  };



  const displayDescription = () => {
    const { description, descriptionAR, descriptionGER } = property;
    
  
    const emptyDescriptions = !descriptionAR && !descriptionGER;
  
    if (emptyDescriptions) {
      return i18n.language === 'en' ? description : description; // English by default
    }
  
    switch (i18n.language) {
      case 'ar':
        return (
    <div
      dangerouslySetInnerHTML={{
        __html: (descriptionAR || description).replace(/\n/g, '<br />')
      }}
    />
  );
      case 'de':
        return (
        <div
        dangerouslySetInnerHTML={{
          __html: (descriptionGER || description).replace(/\n/g, '<br />')
        }}
      />
    );
      default:
        return (
        <div
        dangerouslySetInnerHTML={{
          __html: (description).replace(/\n/g, '<br />')
        }}
      />
    );
    }
  };


  
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem 2rem',
    },
    circle: {
      width: '30px',                
      height: '30px',
      borderRadius: '50%',         
      border: '2px solid red',   
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '10px',            
      color: 'red',              
      marginRight: '8px',          
    },
    text: {
      fontSize: '10px',           
      color: 'red',             
    },
    explanation: {
      fontSize: '12px',             
      color: "rgb(51 51 51 / 67%)",               
      marginLeft: '10px',          
    },
  };

 
      

  return (
    <>
    {isLoading ?(
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <FourSquare color="#FFDDB9" size="medium" text="" textColor="" />
      </div>
    ):(
      
      <section className="py-0 pageDetailsCustumMargin">
        <h2 className='text-center'>{property.propertyName}</h2>
        <div className='d-flex justify-content-center' style={{ maxWidth: "1120px", margin: "0 auto" }}>
          <div className="d-none d-md-block"> {/* PhotoGrid visible on medium and up */}
            <PhotoGrid imgUrls={property.imagesUrl} />
          </div>
     

          <div className="d-block d-md-none"> {/* Carousel visible only on mobile */}
            <div className="container px-4 px-lg-5 my-5">
              <div className="row gx-4 gx-lg-5 align-items-start">
                <div className="col-md-6">
                  <Carousel imgUrls={property.imagesUrl} />
                </div>
                <div className="col-md-6">
                  <h1 className="display-5 fw-bolder mb-3 text-center">{property.propertyName}</h1>
                  <div className="flex flex-wrap justify-center">
                    {[{ icon: faBed, label: property.numBeds },
                      { icon: faShower, label: property.numShower },
                      { icon: faLocationDot, label: property.compound },
                      { icon: faEye, label: property.view },
                    ]
                      .map((badge, index) => (
                        <span key={index} className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
                          <FontAwesomeIcon className='text-center mr-2' size='lg' icon={badge.icon} color="orange" /> {badge.label}
                        </span>
                    ))}
                  </div>
                  <p className="lead mt-3" style={{ direction }}>
                    {displayDescription()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 flex flex-wrap justify-content-center d-none d-md-flex">
      <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
        <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faBed} color="orange" /> {property.numBeds}
      </span>
      <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
        <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faShower} color="orange" /> {property.numShower}
      </span>
      <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
        <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLocationDot} color="orange" /> {property.compound}
      </span>
      <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
        <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faEye} color="orange" /> {property.view}
      </span>
    
    </div>
        <div className="flex flex-wrap justify-content-center mt-0 mt-md-3">
          <hr className="mx-auto mt-0 mb-5 mt-md-2" style={{ width: "50%" }} />
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div className='WhatWeOffer'>
            <div className="container mt-4 col-12 col-md-6">
  <h3 className="text-center">{t("whatThisPlaceOffer")}</h3>
  <div className="row mt-5 justify-content-start">
    {services.map((service, index) => (
      <div className="col-6 d-flex align-items-center mb-2" key={index}>
        {iconMapping[service.icon] ? (
          <FontAwesomeIcon icon={iconMapping[service.icon]} size='lg' className="mr-2" />
        ) : (
          <span className="text-danger">Icon not found</span>
        )}
        <span>
        {
                  i18n.language === 'ar' ?  service.arName:
                  i18n.language === 'de' ? service.deName :
                  service.enName
}

          
          
          </span>
      </div>
    ))}
  </div>
</div>

            </div>
          </div>

          <div style={{
         margin: "0px auto",
         display: "flex",
        //  flex-direction: row
         alignItems: "center",
         alignContent: "space-around",
         justifyContent: "space-around",
          flexWrap: "wrap",
          gap: "5em"
          }}>
           
           <div className="d-none d-md-block" style={{ width: "50em",height:"auto", padding: "0 20px" }}>
  <h4 className='text-md font-medium'style={{ direction }}>{t("description")}</h4>
  <p className="lead mt-3" style={{ direction }}>
    {displayDescription()}
  </p>
</div>


            <form onSubmit={handleFormSubmit} >
              <div className="flex flex-col space-y-4 mt-3">
                <div className="flex flex-col w-full">
                  <label htmlFor="dateFrom" className="text-sm font-medium text-center mb-2">{t("ReserveWithUs")}</label>
               
                    <Calendar
                      value={selectedDayRange}
                      onChange={setSelectedDayRange}
                      colorPrimary="#0fbcf9"
                      colorPrimaryLight="rgba(75, 207, 250, 0.4)"
                      disabledDays={disabledDays}
                      customDaysClassName={packageDays.map(pkg => ({
                        year: pkg.year,
                        month: pkg.month,
                        day: pkg.day,
                        className: pkg.className
                      }))}
                      renderFooter={() => (
                        <div style={styles.container}>
                          <div style={styles.circle}>
                            <span>! </span>
                          </div>
                          <div style={styles.text}>
                           {t("packageDays")}
                          </div>
                          <div style={styles.explanation}>
                         {t("rentCalendarNote")}
                          </div>
                        </div>
                      )}
                    />
               
                </div>

                <p className="lead mt-3" style={{ direction }}>
                  <span style={{ fontWeight: 'bold', direction: 'inherit' }}>
                    {t("pricePerNight")}
                  </span>
                  {property.discount ? (
                    <div style={{ display: 'inline-flex', flexDirection: direction === 'rtl' ? 'row-reverse' : 'row', alignItems: 'center' }}>
                      <span style={{ color: 'red', fontWeight: 'bold', margin: direction === 'rtl' ? '0 10px 0 0' : '0 0 0 10px' }}>
                        {property.discount} {t("curr")}
                      </span>
                      <span style={{ textDecoration: 'line-through', margin: direction === 'rtl' ? '0 10px 0 0' : '0 0 0 10px' }}>
                        {property.price} {t("curr")}
                      </span>
                    </div>
                  ) : (
                    <span>{property.price} {t("curr")}</span>
                  )}
                </p>

                <button className="px-6 mt-3 py-2 text-white bg-black rounded-md hover:bg-blue-600 w-full">
                  {t("reservBtn")}
                </button>

                <RentModal
                  isOpen={isModalOpen}
                  onClose={closeModal}
                  onConfirm={bookApp}
                  bookingDetails={{
                    startDate: formatDate(startDate),
                    endDate: formatDate(endDate),
                    pricePerNight: propertyPrice,
                    totalPrice: totalPrice,
                    discount: property.discount,
                    selectedPackages: selectedPackages,
                    numOfDaysNotInludePackage: NOSelectedDaysNotInPackage
                  }}
                />
              </div>
            </form>
            </div>
          </div>
    
      </section>
  
    )

     }
 </>
  );
};
  
  



export default PropertyDetailsPage;
