import React from 'react';

const RentModal = ({ isOpen, onClose, bookingDetails, onConfirm }) => {
  if (!isOpen) return null;

  const {
    startDate,
    endDate,
    pricePerNight,
    totalPrice,
    discount,
    selectedPackages,
    numOfDaysNotInludePackage
  } = bookingDetails;

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  let totalPackagesPrice = 0;
  if (selectedPackages) {
    for (let i = 0; i < selectedPackages.length; i++) {
      totalPackagesPrice += selectedPackages[i].packagePrice;
    }
  }
  console.log("total packages price : "+totalPackagesPrice);

  // const nightsOutsidePackage =0; // Calculate the nights outside the package based on your logic
  // const totalNights = (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24);
  // const nightsIncludedInPackages = selectedPackages.reduce((acc, pkg) => {
  //   return acc + (new Date(pkg.endDate) - new Date(pkg.startDate)) / (1000 * 60 * 60 * 24);
  // }, 0);
  // const nightsOutside = totalNights - nightsIncludedInPackages;

  return (
    <div
      id="default-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
    >
      <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
        {/* Modal Header */}
        <div className="flex items-center justify-between border-b pb-4 mb-4">
          <h3 className="text-2xl font-semibold text-gray-900">Booking Confirmation</h3>
          <button
            type="button"
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        {/* Modal Body */}
        <div className="max-h-[60vh] overflow-y-auto space-y-6">
         

          {selectedPackages && selectedPackages.length > 0 && (
            <div>
               <div className="border-b pb-4">
            <h4 className="text-lg font-medium text-gray-700">Booking Details</h4>
            <div className="flex justify-between mt-2">
              <span className="font-medium text-gray-600">Start Date:</span>
              <span className="text-gray-500">{new Date(startDate).toLocaleDateString()}</span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-600">End Date:</span>
              <span className="text-gray-500">{new Date(endDate).toLocaleDateString()}</span>
            </div>
          </div>
              <h4 className="text-lg font-medium text-gray-700 mt-4">Selected Packages</h4>
              <div className="space-y-4 mt-2">
                {selectedPackages.map((pkg) => (
                  <div key={pkg._id} className="border p-4 rounded-lg shadow-sm">
                    <div className="flex justify-between">
                      <span className="font-medium text-gray-600">{pkg.packageName}</span>
                      <span className="text-gray-500">{pkg.packagePrice} EGP</span>
                    </div>
                    <div className="flex justify-between mt-2">
                      <span className="font-medium text-gray-600">Package Start Date:</span>
                      <span className="text-gray-500">{new Date(pkg.startDate).toLocaleDateString()}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="font-medium text-gray-600">Package End Date:</span>
                      <span className="text-gray-500">{new Date(pkg.endDate).toLocaleDateString()}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-between mb-2 mt-2">
            <span className="font-medium text-gray-600">Price Per Night (Outside Package):</span>
            <span className="text-gray-500">
              {discount || pricePerNight} EGP
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-600 mb-2">Nights Outside Packages:</span>
            <span className="text-gray-500">{numOfDaysNotInludePackage} nights</span>
          </div>
          <div className="flex justify-between border-t pt-4">
            <span className="font-medium text-gray-700 mb-3">Total Price:</span>
         
         
            <span className="text-gray-500 font-semibold">
                {selectedPackages&&(
                `${(pricePerNight*numOfDaysNotInludePackage) + totalPackagesPrice} `
                )}
              EGP</span>
          </div>
          <div className="text-sm text-gray-600">
            <p>
              Note: The price per night listed above applies to the nights that are outside of the selected packages. 
              Please ensure to review your package details to confirm your booking.
            </p>
          </div>
            </div>
            
          )}
          {selectedPackages.length === 0 &&(
            <div class="space-y-4">
    <div class="flex justify-between">
        <span class="font-medium text-gray-700">Start Date:</span>
        <span class="text-gray-500">{startDate}</span>
    </div>
    <div class="flex justify-between">
        <span class="font-medium text-gray-700">End Date:</span>
        <span class="text-gray-500">{endDate}</span>
    </div>
    <div class="flex justify-between">
        <span class="font-medium text-gray-700">Price Per Night:</span>
        <span class="text-gray-500">{`${pricePerNight} EGP`}</span>
    </div>
    <div class="flex justify-between border-t pt-4">
        <span class="font-medium text-gray-700">Total Price:</span>
        <span class="text-gray-500 font-semibold">{`${totalPrice} EGP` } </span>
    </div>
</div>
          )}

       
        </div>

        {/* Modal Footer */}
        <div className="flex justify-end mt-6 space-x-4">
          <button
            type="button"
            onClick={handleConfirm}
            className="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default RentModal;
