import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import {message} from 'antd'
import { useTranslation } from 'react-i18next';
const ContactUsForm = () => {
  const [t]= useTranslation("global");
  // State to manage form fields
  const [form, setForm] = useState({
    email: '',
    subject: '',
    message: '',
  });

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({
      ...form,
      [id]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const text = `
    New customer application received:
    
    From: ${form.email}
    Subject: ${form.subject}
    Message: ${form.message}
  `;
  
    emailjs
      .send(
        'service_jvmwwqj', // paste your ServiceID here (you'll get one when your service is created).
        'template_v39w1xb', // paste your TemplateID here (you'll find it under email templates).
        {
          from_name: form.email,
          to_name: 'support@luxuryelgounarealestate.com', 
          to_email: 'support@luxuryelgounarealestate.com',
          subject:form.subject,
          message: text,
        },
        '7CHJ5K3dA2WKCthuj' //paste your Public Key here. You'll get it in your profile section.
      )
      .then(
        () => {
      
          message.success(t("message.contactSuccess"));

          setForm({
            name: '',
            email: '',
            message: '',
          });
        },
        (error) => {
   
          console.log(error);
          message.error(t("message.contanctErr"));
        }
      );



    setForm({
      email: '',
      subject: '',
      message: '',
    });
  };

  return (
    <div className="flex justify-around">
      <section className="contactUs dark:bg-gray-900">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
          {t("contactUs.header")}
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
          {t("contactUs.subHeader")}
          </p>
          <form onSubmit={handleSubmit} className="space-y-8">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                  {t("contactUs.email")}
              </label>
              <input
                type="email"
                id="email"
                value={form.email}
                onChange={handleChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="name@gmail.com"
                required
              />
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                  {t("contactUs.subject")}
              </label>
              <input
                type="text"
                id="subject"
                value={form.subject}
                onChange={handleChange}
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder= {t("contactUs.subjectPlaceHolder")}
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                
              >
                {t("contactUs.message")}
              </label>
              <textarea
                id="message"
                rows="6"
                value={form.message}
                onChange={handleChange}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder={t("contactUs.messagePlaceHolder")}
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center text-white bg-black rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
             {t("contactUs.btn")}
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ContactUsForm;
