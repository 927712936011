import React,{useEffect} from 'react';
import HomeImg from '../../assets/home.png'
import WOW from "wowjs";
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const [t,i18n]= useTranslation("global");
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    return () => {
      wow.sync(); 
    };
  }, []);

    return (  
        <div id="aboutUs" className='aboutus'>
          <div className='pt-5'>
        <div className="page-section pb-0">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 py-3 wow fadeInUp">
            <h1 style={{direction}}
        
            dangerouslySetInnerHTML={{ __html:t("aboutUs.title") }}
            />
            <p style={{direction}}
                  className="text-grey mb-4"
                  dangerouslySetInnerHTML={{ __html: t("aboutUs.body") }}
                />
           
          </div>
          <div className="col-lg-6 wow fadeInRight" data-wow-delay="400ms">
            <div className="img-place custom-img-1">
            <img src={HomeImg} alt="" />
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
    );
}
 
export default AboutUs;