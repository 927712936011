import React, { useState } from 'react';
import '../../App.css';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { FaEye } from 'react-icons/fa'; // Import an eye icon from react-icons

const PhotoGrid = ({ imgUrls = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const handleShowAllPhotos = () => {

    setIsOpen(true);
    setCurrentIndex(0); // Optionally set to the first image
  };

  return (
    <div className="photo-grid">
      <div className="photo-large">
        <img
          src={imgUrls[0]}
          alt="Large"
          onClick={() => handleImageClick(0)}
        />
      </div>
      <div className="photo-small-grid">
        {imgUrls.slice(1, 5).map((url, index) => (
          <div className="photo-small" key={index}>
            <img
              src={url}
              alt={`Property image ${index + 1}`}
              onClick={() => handleImageClick(index + 1)}
            />
          </div>
        ))}
      </div>
      <div className="show-all-button">
        <button onClick={handleShowAllPhotos}>
          <FaEye /> Show All Photos
        </button>
      </div>
      {isOpen && (
        <Lightbox
          slides={imgUrls.map((url) => ({ src: url }))}
          open={isOpen}
          index={currentIndex}
          close={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default PhotoGrid;
