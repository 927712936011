import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL ,deleteObject  } from "firebase/storage";
import { storage } from "../../firebaseConfig/firebaseconfig";
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload, Button } from 'antd';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import '../../App.css';

const EditProperty = () => {

  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState(null);
  const [formValues, setFormValues] = useState({
    coverImgUrl: null,
    photos: []
  });
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const propId = queryParams.get('propId');
  const [packageName,setPackageName]=useState('');
  const [packagePrice,setPackagePrice]=useState(null);
  const [selectedDates, setSelectedDates]=useState([]);
  const [disabledDays, setDisabledDays]=useState([]);
  const [bookedDays, setBookedDays]=useState([]);
  const [startDates, setStartDates]=useState([]);
  const [endDates, setEndDates]=useState([]);
  const [highlightedPackagDays, setHighlightedPackagDays]=useState([]);
  const [packageDays,setPackageDays]=useState([]);
  const [clientName, setClientName]=useState([]);
  const [clientPhoneNum, setClientPhoneNum]=useState("");
  const [reservationDates,setResevationsDates]=useState([])
  const [bookedApps,setBookedApps]=useState([])
  const [reservationSelectedDayRange, setSelectedReservationDayRange] = useState({
    from: null,
    to: null
  });
  
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });

  const [selectedServiceNames, setSelectedServiceNames] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const[propertyPackage,setPropertyPackage]=useState([]); 
  const [NOSelectedDaysNotInPackage, setNOSelectedDaysNotInPackage] = useState();
  const services = [
    {"icon": "faWifi", "enName": "WiFi", "arName": "واي فاي", "deName": "WLAN"},
    {"icon": "faTv", "enName": "TV", "arName": "تلفاز", "deName": "Fernseher"},
    {"icon": "faUtensils", "enName": "Kitchen", "arName": "مطبخ", "deName": "Küche"},
    {"icon": "faBowlFood", "enName": "Dishwasher", "arName": "غسالة صحون", "deName": "Geschirrspüler"},
    {"icon": "faParking", "enName": "Free Parking", "arName": "موقف سيارات مجاني", "deName": "Kostenloses Parken"},
    {"icon": "faParking", "enName": "Paid Parking", "arName": "موقف سيارات مدفوع", "deName": "Bezahltes Parken"},
    {"icon": "faSnowflake", "enName": "Air Conditioning", "arName": "تكييف هواء", "deName": "Klimaanlage"},
    {"icon": "faUserTie", "enName": "Private Workspace", "arName": "مساحة عمل خاصة", "deName": "Privater Arbeitsplatz"},
    {"icon": "faWaterLadder", "enName": "Pool", "arName": "بركة", "deName": "Schwimmbad"},
    {"icon": "faHotTub", "enName": "Hot Tub", "arName": "حوض استحمام ساخن", "deName": "Whirlpool"},
    {"icon": "faUmbrella", "enName": "Patio", "arName": "فناء", "deName": "Patio"},
    {"icon": "faFire", "enName": "Grill", "arName": "شواية", "deName": "Grill"},
    {"icon": "faTable", "enName": "Outdoor Dining Area", "arName": "منطقة تناول الطعام الخارجية", "deName": "Außenbereich"},
    {"icon": "faFire", "enName": "Fire Pit", "arName": "موقد نار", "deName": "Feuerstelle"},
    {"icon": "faBaseball", "enName": "Billiards Table", "arName": "طاولة بلياردو", "deName": "Billardtisch"},
    {"icon": "faHouseFire", "enName": "Indoor Fireplace", "arName": "مدفأة داخلية", "deName": "Innenkamin"},
    {"icon": "faMusic", "enName": "Piano", "arName": "بيانو", "deName": "Klavier"},
    {"icon": "faDumbbell", "enName": "Exercise Equipment", "arName": "معدات رياضية", "deName": "Sportgeräte"},
    {"icon": "faWater", "enName": "Lake Access", "arName": "وصول إلى البحيرة", "deName": "Zugang zum See"},
    {"icon": "faUmbrellaBeach", "enName": "Beach Access", "arName": "وصول إلى الشاطئ", "deName": "Zugang zum Strand"},
    {"icon": "faSwimmer","enName": "Private Pool", "arName": "حمام سباحة خاص", "deName": "Privater Pool"}
  ];
  useEffect(() => {
    if (!propId) {
      return;}
    const fetchProp = async () => {
      try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${baseUrl}/property/getPropertyUsingId?propertyId=${propId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        setProperty(responseData.property);
        setFormValues({
          coverImgUrl: responseData.property.coverImgUrl || null,
          photos: responseData.property.imagesUrl || []
        });
      
        let selectedServicesName=[];
        for (let i in responseData.property.services){
            selectedServicesName.push(responseData.property.services[i].enName);

        }
        setSelectedServiceNames(selectedServicesName);
        
      } catch (err) {
        console.error(err);
        message.error('Failed to fetch property data');
      }
    };
   
    fetchProp();

  }, [propId]);
  useEffect(()=>{
    const fetchPropertyPackages = async()=>{
      try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${baseUrl}/package/getPropertyPackages?propId=${propId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        const selectedDatesList = [];
        let startDates=[];
        let endDates=[];
        let fromDateObject={};
        let toDateObject={};
        let date;
        let year;
        let month;
        let day;
      
        const packages = responseData.response;
        for (let i =0 ;i<packages.length;i++) {
      
          startDates.push(packages[i].startDate);
          endDates.push(packages[i].endDate);
           date = new Date(packages[i].startDate);
           year = date.getUTCFullYear();   
           month = date.getUTCMonth() + 1; 
           day = date.getUTCDate();  
          fromDateObject={year:year,month:month,day:day}

          date = new Date(packages[i].endDate);
          year = date.getUTCFullYear();   
          month = date.getUTCMonth() + 1; 
          day = date.getUTCDate(); 
          toDateObject={year:year,month:month,day:day}
 
          selectedDatesList.push({
            from: fromDateObject,
            to: toDateObject,
            packageName: packages[i].packageName,
            packagePrice: packages[i].packagePrice,
            packageId: packages[i]._id,
          });
        

         
        }
        // setSelectedDates((prevDates) => [...prevDates, { from: fromDateObject, to: toDateObject,packageName: packages[i].packageName,packagePrice:packages[i].packagePrice,packageId:packages[i]._id }]);
      setSelectedDates(selectedDatesList);
       setPackageDays(constructDisabledDates(startDates, endDates));
       constructHighlitedPackageDays(packages);
       setPropertyPackage(responseData.response);
  
   
      }catch(err){
        console.log("error in fetching property packages : "+err);
      }
    };
    const fetchDisableDates= async ()=>{
      try{
       
          const baseUrl = process.env.REACT_APP_BASE_URL;
          const response = await fetch(`${baseUrl}/appointment/getDisableDates?appId=${propId}`);
      
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const responseData = await response.json();

          setStartDates(responseData.disabledDates.startDates);  
          setEndDates(responseData.disabledDates.endDates);
          setBookedDays(constructDisabledDates(responseData.disabledDates.startDates,responseData.disabledDates.endDates));
      }catch(err){
      console.log("error in fetching disable dates : "+err );
      }
  }
  const fetchBookedAppointments= async()=>{
 
    try{
           const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${baseUrl}/appointment/acceptAppByPropId?propertyId=${propId}`);
            if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        const bookedApps=responseData.acceptedAppointments;
        bookedApps.forEach((bookedApp)=>{
          let date = new Date(bookedApp.startDate);
          const from={
            year : date.getFullYear(),
            month: date.getMonth()+1,
            day: date.getDate(),
          }
          date = new Date(bookedApp.endDate);
          const to={
            year : date.getFullYear(),
            month: date.getMonth()+1,
            day: date.getDate(),
          }
          let clientName ; 
          let clientPhoneNum;
          if(bookedApp.userId!==null){
            if(bookedApp.userId.role==='user'){
              clientName=`${bookedApp.userId.fname} ${bookedApp.userId.lname}`;
              clientPhoneNum=bookedApp.userId.phoneNum;
            }else{
              clientName=`${bookedApp.userId.fname} ${bookedApp.userId.lname}`;
              clientPhoneNum=bookedApp.userId.phoneNum; 
            }
          }
          else {
            clientName=bookedApp.clientName;
            clientPhoneNum=bookedApp.clientPhoneNum;
          }
          if(bookedApp.packageNames.length >0){
            setResevationsDates((prevDates) => [...prevDates, {_id:bookedApp._id, from: from, to: to,clientName:clientName,clientPhoneNum:clientPhoneNum,packages:bookedApp.packageNames,prices:bookedApp.packagePrices,noOfNightsOutsidePackage:bookedApp.nightsOutsidePackages,pricePerDay:bookedApp.propertyId.price,total:bookedApp.total,propertyId:bookedApp.propertyId}]);
          }else {
            setResevationsDates((prevDates) => [...prevDates, {_id:bookedApp._id, from: from, to: to,clientName:clientName,clientPhoneNum:clientPhoneNum,pricePerDay:bookedApp.propertyId.price,noOfNightsOutsidePackage:bookedApp.nightsOutsidePackages,total:bookedApp.total}]);
          }
          })
          // setResevationsDates((prevDates) => [...prevDates, { from: reservationSelectedDayRange.from, to: reservationSelectedDayRange.to,clientName: clientName,clientPhoneNum:clientPhoneNum,packages:selectedPackages,noOfNightsOutsidePackage:noOfNightsOutsidePackage,total:total}]);
    }catch(err){
      console.log("error in fetching bookings : "+err );
    }
  }
  
    fetchPropertyPackages();
    fetchDisableDates();
    fetchBookedAppointments();
  },[])
useEffect(()=>{

},[reservationDates])


  const handleFinish = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (formValues.coverImgUrl===null){
      message.error('Please provide a valid cover image');
    }else if (formValues.photos.length===0){
      message.error('Please provide a propety images'); 
    }
    else {
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());
    const originalCoverPhoto = property.coverImgUrl;
    const originalPhotos = property.imagesUrl; // Original photos from the server
    // const formValuesPhotosUrls=formValues.photos.map(photo => photo.url);

    const formValuesPhotosUrls = Array.isArray(formValues.photos) 
    ? formValues.photos.map(photo => photo.url) 
    : [];

    const unchangedPhotos = [];
    const addedPhotos = [];
    const removedPhotos = [];
    const uploadedPhotosUrls = [];

for (let photo of originalPhotos) {
  // Check if this original photo URL is in the newPhotos array
  if (!formValuesPhotosUrls.includes(photo) && !formValuesPhotosUrls.includes(undefined)) {

      removedPhotos.push(photo); // If not found in newPhotos, mark it for removal
  } else {
      unchangedPhotos.push(photo);
  }
}

for (let photo of formValues.photos) {
  // Check if the photo is a valid object
  if (typeof photo === 'object' && photo !== null) {
    // Check if the photo does not have a URL or the URL is a blob URL (indicating it's a new image)
    if (!photo.url || photo.url.startsWith('blob:')) {
      addedPhotos.push(photo);
    }
  }
  
}


    if(removedPhotos.length > 0) {
      deleteImages(removedPhotos);
    }
   

    let updatedCoverImgUrl;
    if (formValues.coverImgUrl && typeof formValues.coverImgUrl === 'object') {
            try{
              const coverRef = ref(storage, `coverImages/${formValues.coverImgUrl.name}`);
                  await uploadBytes(coverRef, formValues.coverImgUrl);
                  updatedCoverImgUrl = await getDownloadURL(coverRef);
            }catch(err){
              console.log("error in adding new cover image"+err);
            }

           } else {
      updatedCoverImgUrl = originalCoverPhoto;
    }

   
  
    if (addedPhotos.length > 0) {
      try {
        for (let photo of addedPhotos) {
          const photoRef = ref(storage, `photos/${photo.name}`);
    
          if (!(photo instanceof File)) {

            const response = await fetch(photo.url);
            const blob = await response.blob();
    

            const file = new File([blob], photo.name, { type: "image/jpeg" });
            photo = file; 
          }
    
          const metadata = {
            contentType: photo.type,
          };
    

          await uploadBytes(photoRef, photo, metadata);
          const photoUrl = await getDownloadURL(photoRef);
          uploadedPhotosUrls.push(photoUrl);
        }
      } catch (err) {
        console.log("Error uploading added photos:", err);
      }
    }
    
    const newImgUrls=  [...unchangedPhotos, ...uploadedPhotosUrls];
    
    const servicesObjects=constructServicesObjects(); 

    if(servicesObjects.length===0){
      message.error("please selecte at least one service");
      setLoading(false);
      return;
    }
    try {
      const propertyData = {
        propId:propId,
        propertyName: values.propertyName,
        price: values.price,
        discount:values.discount,
        location: values.location,
        compound: values.compound,
        numBeds: values.numBeds,
        numShower: values.numShower,
        coverImgUrl: updatedCoverImgUrl,
        imagesUrl: newImgUrls,
        view: values.view,
        description: values.description,
        descriptionAR:values.descriptionAR,
        descriptionGER:values.descriptionGER,
        services: servicesObjects,
      };
 
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${baseUrl}/property/updateProp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(propertyData)
      });
      const responseData = await response.json();
      if (responseData) {
        if (responseData.status) {
          message.success(responseData.mesg);
        } else {
          message.error(responseData.mesg);
          // window.location.reload();
        }
      }
    } catch (error) {
      message.error('Error in adding property');
      console.error("Error adding property:", error);
    }
  }
  if(selectedDates.length > 0){
  
      updatePackages();
  }if(reservationDates.length > 0){
    updateReservation();
  }
    setLoading(false);
    setTimeout(()=>{
      window.location.reload();
    },2000)
  };

  const deleteImages= async (deletedPhotos)=>{
    for (let photo of deletedPhotos) {
      const photoRef = ref(storage, photo);
      await deleteObject(photoRef);
    }
  }





  const handleCoverImageChange = (file) => {
    setFormValues(prevValues => ({ ...prevValues, coverImgUrl: file }));
  };

  const handleCoverImageRemove = () => {
    setFormValues(prevValues => ({ ...prevValues, coverImgUrl: null }));
  };

 
  const handlePhotosChange = ({ fileList }) => {

    setFormValues(prevValues => ({
        ...prevValues,
        photos: fileList.map(file => file.originFileObj || file)
    }));
};

const handleRemovePhoto = (file) => {
  
    setFormValues(prevValues => ({
        ...prevValues,
        photos: prevValues.photos.filter(photo => photo.name !== file.name)
    }));
};

  


  const getFileList = (photos) => {
   
    return photos.map(photo => {
      // If the photo is an object with `url`, it's an existing photo from the server
      if (typeof photo === 'string') {
        return {
          uid: photo,
          url: photo,
          name: photo.split('/').pop(),
          status: 'done',
        };
      } else {
        // If the photo is a File object, it's a newly uploaded photo
        return {
          uid: photo.uid,
          name: photo.name,
          status: 'done',
          url: photo.url || URL.createObjectURL(photo), // Generate a URL for local preview
        };
      }
    });
  };
  
  
  const getCoverPhotoFile = (coverPhoto) => {
    if (!coverPhoto) {
      return null; // Return null if coverPhoto is not provided
    }
  
    if (typeof coverPhoto === 'string') {
      // If coverPhoto is a URL string
      return {
        uid: 'cover',
        url: coverPhoto,
        name: coverPhoto.split('/').pop(),
        status: 'done',
      };
    } else if (coverPhoto instanceof File) {
      // If coverPhoto is a File object
      return {
        uid: coverPhoto.uid || 'cover',
        name: coverPhoto.name,
        status: 'done',
        url: URL.createObjectURL(coverPhoto),
      };
    }
  
    return null; 
  };

  const constructDisabledDates = (stDate, enDates) => {
    let disabledDates = [];
  
    const addDatesInRange = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      
      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        disabledDates.push({
          year: date.getFullYear(),
          month: date.getMonth() + 1, 
          day: date.getDate(),
        });
      }

    };
  
 
    for (let i = 0; i < stDate.length; i++) {
      addDatesInRange(stDate[i], enDates[i]);
    }
  

    // setDisabledDays(disabledDates);
    return disabledDates;
  };

  const constructDateObjects = (stDate, enDate) => {
    let disabledDates = [];
      const startDate = new Date(stDate);
      const endDate = new Date(enDate);
      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        disabledDates.push({
          year: date.getFullYear(),
          month: date.getMonth() + 1, 
          day: date.getDate(),
        });
       
      }

      return disabledDates;
    
    };
  
 
  
  


  



  
  useEffect(()=>{
    let startDates=[];
    let endDates=[];
    for (let i =0 ;i<selectedDates.length;i++){
      const from = selectedDates[i].from; 
      const to = selectedDates[i].to;
      const startDate = new Date(Date.UTC(from.year, from.month - 1, from.day));
      const endDate = new Date(Date.UTC(to.year, to.month - 1, to.day));
      startDates.push(startDate);
      endDates.push(endDate);
    
    };
    const newDisabledDays = constructDisabledDates(startDates, endDates);
    setDisabledDays(newDisabledDays);
    
  },[selectedDates])

  useEffect(() => {
    const startDates = [];
    const endDates = [];
  
    for (let i = 0; i < reservationDates.length; i++) {
      const from = reservationDates[i].from; 
      const to = reservationDates[i].to;
      const startDate = new Date(Date.UTC(from.year, from.month - 1, from.day));
      const endDate = new Date(Date.UTC(to.year, to.month - 1, to.day));
      startDates.push(startDate);
      endDates.push(endDate);
    }
  
    const newBookedDays = constructDisabledDates(startDates, endDates);
    setBookedDays(newBookedDays);
  
  }, [reservationDates]);
  

  const convertToLocalizedDate = (dateObj) => {
    const { day, month, year } = dateObj;
    // const date = new Date(year, month - 1, day+1); 
    const date = new Date(Date.UTC(year, month - 1, day));
   return  date.toISOString();
  };

  const removePackage = async (date) => {

    if(date.packageId !== undefined){
      try{
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${baseUrl}/package/deletePackage?packageId=${date.packageId}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        message.success("Package deleted successfully")

      
     
      // setTimeout(()=>{
      //   window.location.reload();
      // },2000)

      }catch(err){
        console.log("error in send remove package to the server"+err);
      }
    }
    setSelectedDates((prevDates)=>prevDates.filter(d=>d!==date))
  };

  const addPackage=()=>{
    if (selectedDayRange.from === null || selectedDayRange.to === null) {
      message.error('please a start and end date');
      return;
    }
    if(packageName===null||packageName===''){
      message.error('please provide a package name');
    }
    else if(packagePrice===null||packagePrice===''){
      message.error('please provide a package price');
    }
    else if(new Date(convertToLocalizedDate(selectedDayRange.from))<=Date.now()){
      message.error('please provide a valid date');
    }
  
    else{
      setSelectedDates((prevDates) => [...prevDates, { from: selectedDayRange.from, to: selectedDayRange.to,packageName: packageName,packagePrice:packagePrice}]);
      setSelectedDayRange({
         from: null,
        to: null});
      setPackageName('');
      setPackagePrice('');
  
    }
    }

    function calculateReservedDays(start, end) {
      const startDateObj = new Date(start.year, start.month - 1, start.day); // month is 0-indexed
      const endDateObj = new Date(end.year, end.month - 1, end.day+1);
   
      const differenceInMillis = endDateObj - startDateObj;

      const differenceInDays = Math.ceil(differenceInMillis / (1000 * 60 * 60 * 24)); // 1000 ms * 60 sec * 60 min * 24 hr
    
      return differenceInDays;
    }

    const addRentReservation= async()=>{
      const phoneNumberRegex = /^0\d{10}$/;
      if (reservationSelectedDayRange.from === null || reservationSelectedDayRange.to === null) {
        message.error('please provide a start and end date');
        return;
      }
      const [selectedPackages,noOfNightsOutsidePackage]= await handleCalender();
      
      if(selectedPackages===false){
        message.error("you cannot select a partial package");
        return;
      }
      // setSelectedPackages(selectedPackage);
      if(clientName===''||clientName===null){
        message.error('Please enter client name');
      }
      else if (!phoneNumberRegex.test(clientPhoneNum)) { 
        message.error('Please provide a valid phone number');
      }
   
      else if(new Date(convertToLocalizedDate(reservationSelectedDayRange.from))<=Date.now()){
        message.error('Please provide a valid date');
      }
    
      else{
        let total = 0;
        let propertyPrice=0;
        if(property.discount)
          propertyPrice=property.discount;
        else 
        propertyPrice = property.price;

        if(selectedPackages.length > 0 && Array.isArray(selectedPackages)){
         
         
          selectedPackages.forEach(pkg => {
              total += pkg.packagePrice;
          });
          total+=noOfNightsOutsidePackage*propertyPrice;
          
       
          setResevationsDates((prevDates) => [...prevDates, { from: reservationSelectedDayRange.from, to: reservationSelectedDayRange.to,clientName: clientName,clientPhoneNum:clientPhoneNum,packages:selectedPackages,pricePerDay:propertyPrice,noOfNightsOutsidePackage:noOfNightsOutsidePackage,total:total}]);
        }

        else{
          const numReservedDays=calculateReservedDays(reservationSelectedDayRange.from,reservationSelectedDayRange.to);
          total=numReservedDays*propertyPrice;
        setResevationsDates((prevDates) => [...prevDates, { from: reservationSelectedDayRange.from, to: reservationSelectedDayRange.to,clientName: clientName,clientPhoneNum:clientPhoneNum,pricePerDay:propertyPrice,noOfNightsOutsidePackage:numReservedDays,total:total}]);
        }
        setSelectedReservationDayRange({
           from: null,
          to: null});
        setClientName('');
        setClientPhoneNum('')
      
    }
      }

      const removeReservation = async (date) => {
        if(date._id !== undefined){
          try{
            const baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${baseUrl}/appointment/deleteApp?appId=${date._id}`,{
              method: 'DELETE',
              headers:{
              contentType: 'application/json'
              }
            });
    
            if (!response.ok) { 
              throw new Error('Network response was not ok');
            }
            message.success("Reservation deleted successfully")
    
          
            setTimeout(() => { 
              window.location.reload();
            }, 2000);
    
          }catch(err){
            console.log("error in send remove reservation to the server"+err);
          }
        }
        setResevationsDates((prevDates)=>prevDates.filter(d=>d!==date))
      };





      const convertToDateObject = (dateObj) => {
        return new Date(dateObj.year, dateObj.month - 1, dateObj.day);
      };


      const handleCalender =async () => {
        setSelectedPackages([]);
        let selectedPackages = [];
        
      
        const selectedStart = convertToDateObject(reservationSelectedDayRange.from);
        const selectedEnd = convertToDateObject(reservationSelectedDayRange.to);
        selectedStart.setHours(0, 0, 0, 0); 
        selectedEnd.setHours(0, 0, 0, 0); 
      
        let packageRanges = [];
        let isFullPackageFound = false;
        let isPartialPackageFound = false;
        let twoPackagesFlag = false; // Flag to check for two packages
      
        propertyPackage.forEach((pkg) => {
          const packageStart = new Date(pkg.startDate);
          const packageEnd = new Date(pkg.endDate);
          packageStart.setHours(0, 0, 0, 0);
          packageEnd.setHours(0, 0, 0, 0);
      
          const isPackageFullyIncluded = (
            selectedStart <= packageStart && 
            selectedEnd >= packageEnd
          );
      
          const isPackagePartiallyIncluded = (
            selectedStart <= packageEnd && selectedEnd >= packageStart
          );
      
          if (isPackageFullyIncluded || isPackagePartiallyIncluded) {
            packageRanges.push({ start: packageStart, end: packageEnd });
          //  setSelectedPackages((prevPackages) => [...prevPackages, pkg]);
           selectedPackages.push(pkg)
            if (isPackageFullyIncluded) {
              isFullPackageFound = true;
            } else {
              isPartialPackageFound = true;
            }
          }
        });
      
        if (packageRanges.length === 0) {
          return [selectedPackages, 0]; 
        }
      
        // Set the flag for two packages
        if (packageRanges.length > 1) {
          twoPackagesFlag = true;
        }
      
        packageRanges.sort((a, b) => a.start - b.start); // Sort by start date
      
        // Merge overlapping or adjacent ranges
        let mergedRanges = [packageRanges[0]];
        for (let i = 1; i < packageRanges.length; i++) {
          let lastRange = mergedRanges[mergedRanges.length - 1];
          let currentRange = packageRanges[i];
      
          // Check if packages overlap or are adjacent (no gap between them)
          if (currentRange.start <= lastRange.end || 
              (lastRange.end.getTime() + (1000 * 60 * 60 * 24)) >= currentRange.start.getTime()) {
            // Merge ranges by extending the last range's end
            lastRange.end = new Date(Math.max(lastRange.end, currentRange.end));
          } else {
            // If no overlap or adjacency, add as a new range
            mergedRanges.push(currentRange);
          }
        }
      
        const calculateNights = (start, end) => {
          return Math.max(0, Math.floor((end - start) / (1000 * 60 * 60 * 24))); // Correct night calculation
        };
      
        let daysOutsidePackages = 0;
      
        // Calculate days before the first merged package
        if (selectedStart < mergedRanges[0].start) {
          daysOutsidePackages += calculateNights(selectedStart, mergedRanges[0].start);
        }
      
        // Calculate days after the last merged package
        if (selectedEnd > mergedRanges[mergedRanges.length - 1].end) {
          daysOutsidePackages += calculateNights(mergedRanges[mergedRanges.length - 1].end, selectedEnd);
        }
      
        // Calculate the gap days between merged package ranges
        for (let i = 0; i < mergedRanges.length - 1; i++) {
          // Calculate the gap between the end of the current range and the start of the next
          const gapNights = calculateNights(mergedRanges[i].end, mergedRanges[i + 1].start);
          // Only add gap nights if there is an actual gap
          if (gapNights > 0) {
            daysOutsidePackages += gapNights; // Add the gap days correctly
          }
        }
      
        // If two packages are found, subtract one day from outside packages
        if (twoPackagesFlag) {
          daysOutsidePackages -= 1;
        }
      
        setNOSelectedDaysNotInPackage(daysOutsidePackages);
      
         if (isPartialPackageFound) {
          return false;
        }
        if (isFullPackageFound && isPartialPackageFound && twoPackagesFlag) {
          return false; 
      }else {
        return [selectedPackages,daysOutsidePackages];
      }
      
      };
    const constructHighlitedPackageDays = (pckgs) => {
      let packageDays = [];
      pckgs.forEach((pckg)=>{
        const startDate = new Date(pckg.startDate);
        const endDate = new Date(pckg.endDate);
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          packageDays.push({
            year: date.getFullYear(),
            month: date.getMonth() + 1, 
            day: date.getDate(),
            className:"redDay",
            packageId: pckg._id,
            packageName: pckg.packageName,
            packagePrice: pckg.packagePrice,
          });
        }
      })    
      setHighlightedPackagDays(packageDays);
      };
    const updatePackages= async ()=>{
      let packageTobeSaved=[];
      for (let i =0 ;i<selectedDates.length ;i++){
        if(selectedDates[i].packageId===undefined){
          packageTobeSaved.push(selectedDates[i]);
        }
      }
      
        try{
          const baseUrl = process.env.REACT_APP_BASE_URL;
          const response = await fetch(`${baseUrl}/package/addPackages`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              packageTobeSaved,
              propId
            })
          });
        }catch(err){
          console.log("error in saving Packages : "+err);
        }
    }

    const updateReservation = async()=>{
      let appointments=[];
      let packagesNames=[]; 
      let packagesPrices=[];

      reservationDates.forEach((reservation)=>{
        packagesNames=[];
        packagesPrices=[];
        // ||reservation.propertyId
        if(reservation._id===undefined){
          if(reservation.packages!==undefined){
          reservation.packages.forEach((pkg)=>{
            packagesNames.push(pkg.packageName);
            packagesPrices.push(pkg.packagePrice);
          })
        }
          appointments.push({
            startDate:convertToLocalizedDate(reservation.from),
            endDate:convertToLocalizedDate(reservation.to),
            propertyId:property._id,
            bookedBy:"admin",
            status:"accepted",
            packageNames:packagesNames,
            packagePrices:packagesPrices,
            clientName:reservation.clientName,
            clientPhoneNum:reservation.clientPhoneNum,
            nightsOutsidePackages:reservation.noOfNightsOutsidePackage,
            total:reservation.total,
            display:true
          })
        }   
      })

      appointments.forEach(async (appointment)=>{
      
      try{
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${baseUrl}/appointment/bookApp`,{
          method: 'POST',
          headers: {
            'Content-type':"application/json",
          },
          body:JSON.stringify(appointment)
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

      }catch(err){
        console.log("error in updating appoinments : "+err);
      }
    })
    }
  
    const constructServicesObjects=()=>{
      let serviceObjects =[];
      for (let i =0 ;i<services.length;i++){
        for (let j =0 ;j<selectedServiceNames.length;j++){
          if(services[i].enName===selectedServiceNames[j]){
            serviceObjects.push(services[i]);
          }
        }
      }
    return serviceObjects;
  
    };
    const handleCheckboxChange = (service) => {
   
      setSelectedServiceNames((prev) => 
        prev.includes(service.enName) 
          ? prev.filter((s) => s !== service.enName) 
          : [...prev, service.enName] 
      );
    };
  

  if (!property) {
    return <div>Loading...</div>;
  }

  return (
    <div className="customPadding flex flex-col items-center justify-center my-5 px-4">
      <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold text-center mb-4">Edit Property</h1>
        <form onSubmit={handleFinish} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Other form fields */}
            <div className="flex flex-col">
       
              <label htmlFor="propertyName" className="text-sm font-medium mb-2">Property Name</label>
              <input
                id="propertyName"
                name="propertyName"
                type="text"
                placeholder="Enter property name"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.propertyName}
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="compound" className="text-sm font-medium mb-2">Compound</label>
              <input
                id="compound"
                name="compound"
                type="text"
                placeholder="Enter compound name"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.compound}
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="location" className="text-sm font-medium mb-2">Location</label>
              <input
                id="location"
                name="location"
                type="text"
                placeholder="Enter location"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.location}
                required
              />
            </div>

        

            <div className="flex flex-col">
              <label htmlFor="numBeds" className="text-sm font-medium mb-2">Number of Bedrooms</label>
              <input
                id="numBeds"
                name="numBeds"
                type="number"
                placeholder="Enter number of beds"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.numBeds}
                min="1"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="numShower" className="text-sm font-medium mb-2">Number of Bathrooms</label>
              <input
                id="numShower"
                name="numShower"
                type="number"
                placeholder="Enter number of showers"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.numShower}
                min="1"
                required
              />
            </div>

           

            <div className="flex flex-col">
              <label htmlFor="view" className="text-sm font-medium mb-2">View</label>
              <select
                id="view"
                name="view"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.view}
                required
              >
                <option value="">Select view</option>
                <option value="sea">Sea</option>
                <option value="garden">Garden</option>
                <option value="pool">Pool</option>
                <option value="city">City</option>
                <option value="marina">Marina</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="price" className="text-sm font-medium mb-2">Price</label>
              <input
                id="price"
                name="price"
                type="number"
                placeholder="Enter price"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.price}
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="discount" className="text-sm font-medium mb-2">Price after discount</label>
              <input
                id="discount"
                name="discount"
                type="number"
                placeholder="Enter price after discount"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.discount}

              />
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="description" className="text-sm font-medium mb-2">English Description</label>
            <textarea
              id="description"
              name="description"
              placeholder="Enter description"
              className="p-2 border border-gray-300 rounded"
              defaultValue={property.description}
             
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="descriptionAR" className="text-sm font-medium mb-2">Arabic Description</label>
            <textarea
              id="descriptionAR"
              name="descriptionAR"
              placeholder="Enter arabic description"
              className="p-2 border border-gray-300 rounded"
              defaultValue={property.descriptionAR}
            
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="descriptionGER" className="text-sm font-medium mb-2">German Description</label>
            <textarea
              id="descriptionGER"
              name="descriptionGER"
              placeholder="Enter german description"
              className="p-2 border border-gray-300 rounded"
              defaultValue={property.descriptionGER}
             
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="coverImgUrl" className="text-sm font-medium mb-2">Cover Image</label>
            <Upload
              name="coverImgUrl"
              listType="picture"
              maxCount={1}
              fileList={formValues.coverImgUrl ? [getCoverPhotoFile(formValues.coverImgUrl)] : []}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
              beforeUpload={(file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                  message.error('You can only upload JPG/PNG file!');
                }
                return isJpgOrPng;
              }}
              customRequest={({ file, onSuccess }) => {
                handleCoverImageChange(file);
                onSuccess();
              }}
              onRemove={handleCoverImageRemove}
            >
              <Button icon={<UploadOutlined />}>Upload Cover Image</Button>
            </Upload>
          </div>

          <div className="flex flex-col">
            <label htmlFor="photos" className="text-sm font-medium mb-2">Photos</label>
            <Upload
              name="photos"
              listType="picture-card"
              multiple
              fileList={getFileList(formValues.photos)}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
              style={{ width: '20em' }}
              beforeUpload={(file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                  message.error('You can only upload JPG/PNG files!');
                }
                return isJpgOrPng;
              }}
              onChange={handlePhotosChange}
              onRemove={handleRemovePhoto}
            >
              <Button icon={<UploadOutlined />} style={{ textAlign: 'center' }}>
                Upload Photos
              </Button>
            </Upload>
          </div>
          <div>
          <div
  className="form-group d-flex flex-column align-items-center"
  style={{
    border: '1px solid black',
    borderRadius: '25px',
    padding: '5px',
    marginBottom: '20px', 
  }}
>
  <h4 className="text-center" style={{ marginBottom: '15px' ,marginTop:"10px"}}>
    What We Offer ?
  </h4>
  <div className="d-flex justify-content-center flex-wrap">
      {services.map((service, index) => (
        <div
          className="form-check"
          key={index} 
          style={{
            flex: '0 1 180px',
            border: '1px solid black',
            borderRadius: '20px',
            transition: 'background-color 0.3s',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '10px',
            padding: '10px',
            backgroundColor: '#f8f9fa',
            overflow: 'hidden',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e9ecef')}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#f8f9fa')}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id={`service-${index}`} 
            checked={selectedServiceNames.includes(service.enName)} // Check if the enName is in selectedServices
            onChange={() => handleCheckboxChange(service)} 
            style={{ marginLeft: '2px', marginRight: '2px' }}
          />
          <label className="form-check-label" htmlFor={`service-${index}`}>
            {service.enName} 
          </label>
        </div>
      ))}
    </div>

</div>



          <hr className="mx-auto mt-5 mb-2" style={{ width: "50%" }} />
          <h4 className="text-center pb-2">Add Packages</h4>
<div className="d-flex justify-content-around flex-wrap">
<div class="packResTable relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Package Name
                </th>
                <th scope="col" class="px-6 py-3">
                    Price
                </th>
                <th scope="col" class="px-6 py-3">
                    From
                </th>
                <th scope="col" class="px-6 py-3">
                    To
                </th>
                <th scope="col" class="px-6 py-3">
                    Action
                </th>
            </tr>
        </thead>
        <tbody>
        {selectedDates.map((date, index) => (
      <tr key={index} class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                 {date.packageName}
                </th>
                <td>{`${date.packagePrice} EGP`}</td>
        <td>{`${date.from.day}/${date.from.month}/${date.from.year}`}</td>
        <td>{`${date.to.day}/${date.to.month}/${date.to.year}`}</td>
        <td class="px-6 py-4">
                    <a class="font-medium text-blue-600 dark:text-blue-500 no-underline hover:cursor-pointer" onClick={()=>removePackage(date)}>Remove</a> </td>
      </tr>
    ))}
        </tbody>
    </table>
 
</div>

<div style={{ transform: 'scale(0.8)', transformOrigin: 'top left' }}>
  <Calendar
    value={selectedDayRange}
    onChange={setSelectedDayRange}
    disabledDays={disabledDays}
    shouldHighlightWeekends
    renderFooter={() => (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0.5rem 1rem' }}>
        <input
          type="text"
          value={packageName} 
          onChange={(e) => setPackageName(e.target.value)}
          placeholder="Enter Package Name"
          style={{
            marginBottom: '0.5rem',
            padding: '0.25rem',
            borderRadius: '0.3rem',
            border: '1px solid #ccc',
            fontSize: '0.9rem',
          }}
        />
        <input
          type="number"
          value={packagePrice} 
          onChange={(e) => setPackagePrice(e.target.value)}
          placeholder="Enter Package Price"
          style={{
            marginBottom: '0.5rem',
            padding: '0.25rem',
            borderRadius: '0.3rem',
            border: '1px solid #ccc',
            fontSize: '0.9rem',
          }}
        />
        <button
          type="button"
          onClick={addPackage}
          style={{
            color: 'white',
            backgroundColor: '#0076FF',
            borderRadius: '0.3rem',
            padding: '0.5rem 1rem',
            fontSize: '0.9rem',
          }}
        >
          Add Package
        </button>
      </div>
    )}
  />
</div>

</div>

</div>
        
          <hr className="mx-auto mt-5 mb-2" style={{ width: "50%" }} />
          <h4 className="text-center">Reserve appoinments</h4>
          <div className="d-flex flex-wrap justify-content-around ">
          <div class="packResTable relative overflow-x-auto shadow-md sm:rounded-lg">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                          <th scope="col" class="px-6 py-3">
                              Client Name
                          </th>
                          <th scope="col" class="px-6 py-3">
                              Phone number
                          </th>
                          <th scope="col" class="px-6 py-3">
                              From
                          </th>
                          <th scope="col" class="px-6 py-3">
                              To
                          </th>
                          <th scope="col" class="px-6 py-3">
                              Package
                          </th>
                          <th scope="col" class="px-6 py-3">
                              Package price
                          </th>
                          <th scope="col" class="px-6 py-3">
                              price per day
                          </th>
                          <th scope="col" class="px-6 py-3">
                              Nights Outside Package
                          </th>
                          <th scope="col" class="px-6 py-3">
                              total
                          </th>
                          <th scope="col" class="px-6 py-3">
                              Action
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                  {reservationDates.map((date, index) => (
                <tr key={index} class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                           {date.clientName}
                          </th>
                          <td className="text-center">{date.clientPhoneNum}</td>
                  <td className="text-center">{`${date.from.day}/${date.from.month}/${date.from.year}`}</td>
                  <td className="text-center">{`${date.to.day}/${date.to.month}/${date.to.year}`}</td>
                  {date.propertyId ? (
                  <>
                  <td className="text-center">{Array.isArray(date.packages) && date.packages.length > 0 ? date.packages.map(pkg => pkg).join(', ') : 'NA'}</td>
                  <td className="text-center">{Array.isArray(date.prices) && date.prices.length > 0 ? date.prices.map(price => price).join(', ') : 'NA'}</td>
                  </>
                  ):(
                        <>
                        <td className="text-center">{Array.isArray(date.packages) && date.packages.length > 0 ? date.packages.map(pkg => pkg.packageName).join(', ') : 'NA'}</td>
                        <td className="text-center">{Array.isArray(date.packages) && date.packages.length > 0 ? date.packages.map(pkg => pkg.packagePrice).join(', ') : 'NA'}</td>
                        </>
                  )
                
                  }
                  <td className="text-center">{date.pricePerDay}</td>
                  <td className="text-center">{date.noOfNightsOutsidePackage}</td>

                  <td className="text-center">{date.total}</td>
                  
                  
                  <td class="px-6 py-4">
                  <a class="font-medium text-blue-600 dark:text-blue-500 no-underline hover:cursor-pointer" onClick={()=>removeReservation(date)}>Remove</a> 
                              
                          </td>
                </tr>
              ))}
                  </tbody>
              </table>
           
          </div>
          <div className="mx-0" style={{ transform: 'scale(0.8)', transformOrigin: 'top left' }}>
  <Calendar
    value={reservationSelectedDayRange}
    onChange={setSelectedReservationDayRange}
    disabledDays={bookedDays}
    shouldHighlightWeekends
    customDaysClassName={highlightedPackagDays.map((pkg) => ({
      year: pkg.year,
      month: pkg.month,
      day: pkg.day,
      className: pkg.className
    
    }))}
    renderFooter={() => (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0.5rem 1rem' }}>
      <input
          type="text"
          value={clientName} 
          onChange={(e) => setClientName(e.target.value)}
          placeholder="Enter Client Name"
          style={{
            marginBottom: '0.5rem',
            padding: '0.25rem',
            borderRadius: '0.3rem',
            border: '1px solid #ccc',
            fontSize: '0.9rem',
          }}
        />
         <input
          type="text"
          value={clientPhoneNum} 
          onChange={(e) => setClientPhoneNum(e.target.value)}
          placeholder="Enter Phone Number"
          style={{
            marginBottom: '0.5rem',
            padding: '0.25rem',
            borderRadius: '0.3rem',
            border: '1px solid #ccc',
            fontSize: '0.9rem',
          }}
        />
        <button
          type="button"
          onClick={async () => { 
            await addRentReservation(); 
          }}
          style={{
            color: 'white',
            backgroundColor: '#0076FF',
            borderRadius: '0.3rem',
            padding: '0.5rem 1rem',
            fontSize: '0.9rem',
          }}
        >
         Reserve Appoinment
        </button>
      </div>
    )}
  />
</div>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="px-6 py-2 bg-black text-white rounded hover:bg-gray-800 transition"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Update Property'}
            </button>

         
          </div>
        </form>
      
      </div>
    </div>
  );
};

export default EditProperty;
