import React from 'react';
import '../../App.css';
import video from '../../assets/homepagehero.mp4';
import { useTranslation } from 'react-i18next';
const Header = () => {
    const [t,i18n]= useTranslation("global");

    return (
        <div className="video-container relative">
            <video className="background-video" autoPlay loop muted playsInline>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="overlay absolute inset-0 bg-black opacity-50"></div>
            <div className="content flex justify-center items-center relative">
                {/* Your content here */}
                <h1 className="text-white">{t("header.message")}</h1>
                {/* Discover Your Dream Home */}
            </div>
        </div>
    );
};

export default Header;
