import React,{useEffect,useState} from 'react';
import {Link} from'react-router-dom'
import {message } from "antd"
import {useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ChangePassForm = () => {
    const [t]= useTranslation("global");
    const navigate = useNavigate();
    const [userId,setUserId]=useState();
    useEffect(()=>{
        window.scrollTo(0, 0);
        if (!sessionStorage.getItem('token')) {
            localStorage.setItem('redirectAfterLogin', window.location.pathname+window.location.search);
            navigate('/logIn');
            return;
        }
        window.scrollTo(0, 0);
        setUserId(sessionStorage.getItem('userId'));
    },[])
    const submitHandler =async (e) => {
     
        e.preventDefault();
        const formData = new FormData(e.target);
        const formPayload = Object.fromEntries(formData);
    
        if (formPayload.oldPass === ''||formPayload.newPass===''||formPayload.repeatePass==='') {
           
            message.error(t("message.requiredFileds"));
           
        }
        
        else if(formPayload.newPass!==formPayload.repeatPass){
            message.error(t("message.passNotMatch"));
        }
        else if (formPayload.oldPass === formPayload.newPass){
            message.error(t("message.oldPassNotMatch"));
        }
        else if (formPayload.newPass.length<5){
            message.error(t("message.passSmall"));
        }

       else {
        try {
            const data ={
                userId:userId,
                pass:formPayload.newPass,
                oldPass:formPayload.oldPass
            };
           
            
       
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/changePass`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
           
                 
    
                const result = await response.json();
                if (result.success) {
                    message.success(result.msg);
                } else {
                    message.error(result.msg || 'Failed to changed password');
                }
            } catch (err) {
                console.error('Error in changing password:', err);
                message.error(t("message.serverError"));
            }
       }



    };
    return (
        <section className="min-h-screen flex items-center justify-center pt-20 bg-gray-50">
        <div className="w-full max-w-md bg-white rounded-lg shadow border border-gray-200">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                   {t("changePass.header")}
                </h1>

                <form onSubmit={submitHandler} className="space-y-4 md:space-y-6" action="#">
                    <div>
                        <label htmlFor="oldPass" className="block mb-2 text-sm font-medium text-gray-900">{t("changePass.pass")}</label>
                        <input type="password" name="oldPass" id="oldPass" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder={t("changePass.passPlaceHolder")} required=""/>
                    </div>
                    <div>
                        <label htmlFor="newPass" className="block mb-2 text-sm font-medium text-gray-900">{t("changePass.newPass")}</label>
                        <input type="password" name="newPass" id="newPass" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder={t("changePass.newPassPlaceHolder")} required=""/>
                    </div>
                    <div>
                        <label htmlFor="repeatPass" className="block mb-2 text-sm font-medium text-gray-900">{t("changePass.repeatPass")}</label>
                        <input type="password" name="repeatPass" id="repeatPass" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder={t("changePass.repeatPssPlaceHolder")} required=""/>
                    </div>
                  
                    <button type="submit" className="w-full text-white bg-black focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">{t("changePass.changePassbtn")}</button>
                    <p className="text-sm font-light text-gray-500">
                    {t("changePass.singUpDes")} 
                        <Link to="/signUP" className="font-medium text-primary-600 hover:underline">{t("changePass.signUpLink")}</Link>
                    </p>
                </form>
            </div>
        </div>
    </section>

      );
}
 
export default ChangePassForm;