import React,{ useState} from 'react';
import {Link,Navigate, useNavigate,} from'react-router-dom'
import {message } from "antd"
import { useTranslation } from 'react-i18next';

const ForgotPassForm = () => {
    const [t]= useTranslation("global");
    const navigate=useNavigate();
    const [loading,setLoading]=useState(false);
    const submitHandler =async (e) => {
        e.preventDefault();
    
        const formData = new FormData(e.target);
        const formPayload = Object.fromEntries(formData);
    
        if (formPayload.email === '') {
           
            message.error(t("message.emailEmpty"));
           
        }
    
        
        
    
       else {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/requestResetPass?email=${formPayload.email}`);
                const result = await response.json();
               
                if (result.response) {
                    message.success('An email with a reset link has been sent to your email address.');
                    navigate('/');
                } else {
                    message.error(result.response || 'Failed to send reset link');
                }
                setLoading(false);
            } catch (err) {
                console.error('Error in sending reset link:', err);
                message.error('An error occurred while sending the reset link');
            }
       }



    };
    return ( 
        <section className="min-h-screen flex items-center justify-center pt-20 bg-gray-50">
        <div className="w-full max-w-md bg-white rounded-lg shadow border border-gray-200">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                   {t("forgotPass.header")}
                </h1>

                <form onSubmit={submitHandler} className="space-y-4 md:space-y-6" action="#">
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">{t("forgotPass.email")}</label>
                        <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="name@company.com" required=""/>
                    </div>
                  
                    <button type="submit" className="w-full text-white bg-black focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">

                    {loading ? t("forgotPass.signLoading") : t("forgotPass.signUpLink")}

                        </button>
                    <p className="text-sm font-light text-gray-500">
                        Don’t have an account yet? 
                        <Link to="/signUP" className="font-medium text-primary-600 hover:underline">Sign up</Link>
                    </p>
                </form>
            </div>
        </div>
    </section>
     );
}
 
export default ForgotPassForm