import AuthContext from "./authContext";
import {useEffect, useState} from 'react';
import { auth } from "../firebaseConfig/firebaseconfig";
import { onAuthStateChanged } from "firebase/auth";



const Authprovider = (props) => {
  const [userId, setId] = useState('');
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [role, setRole] = useState('');
    const authContext = {
        email: email,
        userId: userId,
        token: token,
        role: role,
        login: (email,userId, token,role) => {
          
          setEmail(email);
            setId(userId);
            setToken(token);
            setRole(role);
            sessionStorage.setItem('userId', userId);
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('role', role);
            sessionStorage.setItem('email', email);
         
          },
        logout: () => { 
          setEmail('');
          setId('');
          setToken('');
          setRole('');
          sessionStorage.removeItem('userId');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('role');
          sessionStorage.removeItem('email');
        }
      };
      useEffect(()=>{
        const unsubscribe = onAuthStateChanged(auth,initializUser);
        return unsubscribe;
      },[])
      
      const initializUser =(user)=>{


      }
    return (
      <AuthContext.Provider value={authContext}>
        {props.children}
      </AuthContext.Provider>
    );
  };
  
  export default Authprovider;
  