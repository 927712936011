import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="customMargin container mx-auto p-5 border border-gray-300 rounded-lg shadow-md">
            <h1 className="text-center text-2xl font-bold mb-4">Privacy Policy</h1>
            <p><strong>Effective Date:</strong> 10/5/2024</p>

            <p>
                <strong>EL Gouna Luxury™</strong> ("we," "us," or "our") operates the website 
                <a href="https://luxuryelgounarealestate.com" target="_blank" rel="noopener noreferrer"> www.luxuryelgounarealestate.com/</a> 
                (the "Site") and any related services (collectively, the "Services"). This privacy policy explains how we collect, use, disclose, and safeguard your information when you use our Services.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">1. Information We Collect</h2>
            <h3 className="font-medium">Personal Information</h3>
            <p>
                When you register for our Services or use our Site, we may collect personal information that can identify you, such as:
                <ul className="list-disc ml-6">
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                </ul>
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">2. How We Use Your Information</h2>
            <p>
                We may use the information we collect for various purposes, including to:
                <ul className="list-disc ml-6">
                    <li>Provide, maintain, and improve our Services</li>
                    <li>Process transactions and send you related information</li>
                    <li>Communicate with you about products, services, offers, and promotions</li>
                    <li>Respond to your comments, questions, and requests</li>
                    <li>Monitor usage and analyze trends to improve your experience</li>
                </ul>
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">3. Information Sharing</h2>
            <p>
                We do not sell, rent, or lease your personal information to third parties. We may share your information with:
                <ul className="list-disc ml-6">
                    <li>Service providers that help us operate our business (e.g., payment processors, email delivery services)</li>
                    <li>Legal authorities if required by law or to protect our rights</li>
                    <li>Third parties in connection with a merger, sale, or acquisition of our business</li>
                </ul>
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">4. User Rights</h2>
            <p>
                You have the following rights regarding your personal information:
                <ul className="list-disc ml-6">
                    <li><strong>Access:</strong> You can request a copy of the personal information we hold about you.</li>
                    <li><strong>Correction:</strong> You can request correction of any inaccuracies in your personal information.</li>
                    <li><strong>Deletion:</strong> You can request deletion of your personal information, subject to certain exceptions (e.g., if we need to keep it for legal reasons).</li>
                </ul>
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">5. Data Security</h2>
            <p>
                We implement reasonable security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">6. Cookies and Tracking Technologies</h2>
            <p>
                We may use cookies and similar tracking technologies to enhance your experience on our Site. You can set your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some features of our Services.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">7. Changes to This Privacy Policy</h2>
            <p>
                We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">8. Contact Us</h2>
            <p>
                If you have any questions about this privacy policy, please contact us at:
            </p>
            <p>
                <strong>Email:</strong> <a href="mailto:support@luxuryelgounarealestate.com">support@luxuryelgounarealestate.com</a><br />
                <strong>Phone:</strong> 01023100148<br />
            </p>
        </div>
    );
};

export default PrivacyPolicy;
