import React, { useEffect, useState } from "react";
import { Table,} from "antd";
import Button from 'react-bootstrap/Button';
import emailjs from '@emailjs/browser';

const AppointmentList = () => {
    const [appointments,setAppointments] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [refresh , setRefresh] = useState();
 
 
    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/appointment/getPendingApps`);
                const responseData = await response.json();
              
                const appointments=responseData.pendingAppointments;
                const rentPending = appointments.filter((app) => {
                    if (!app.propertyId) {
                        return false;
                    }
                    return app.propertyId.propertyType.includes("rent");
                });
                setAppointments(rentPending);
               
            } catch (err) {
                console.log("Error in fetchProperties:", err);
            }
        };
        fetchProperties();
    }, [refresh]);

    const handleAccept=async(id)=>{
try{
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const response = await fetch(`${baseUrl}/appointment/acceptApp?appId=${id}`);
    sendEmail(id);
    setRefresh(prev => !prev);
   
  
}catch(err){
    console.log("error in accepting appointment:", err);
}

    }
    const handleReject=async(id)=>{
     
        try{
            const baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${baseUrl}/appointment/deleteApp?appId=${id}`, {
                method: 'DELETE',  // Explicitly use DELETE method
            });
            setRefresh(prev => !prev);
          
        }catch(err){
            console.log("error in accepting appointment:", err);
        }
        
            }
        
            const sendEmail = async(id)=>{
                try{
                    const baseUrl = process.env.REACT_APP_BASE_URL;
                    const response = await fetch(`${baseUrl}/appointment/getAppDetails?appId=${id}`);
                    
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json(); 
                    
              
                  
                    const name = data.userId.fname;
                    const email = data.userId.email;
                    let date = new Date(data.startDate);
                    const formattedStartDate = date.toLocaleDateString();
                    date = new Date(data.endDate);
                    const formattedEndDate = date.toLocaleDateString();
                    const location = data.propertyId.location;
                    const compound= data.propertyId.compound ;
                    const numOfNights= data.nightsOutsidePackages;
                    const time=data.time;
                    const propertyName= data.propertyId.propertyName;
                    const pricePerDay = data.propertyId.price;
                    const total = data.total;
             
                    let  subject="Booking Confirmation from luxuryelgounarealestate";
                    let message;
                if(data.packageNames.length ===0) {
                    
                     message =` We are pleased to confirm your appointment with luxuryelgounarealestate. Below are the details of your appointment:
    
    Start Date: ${formattedStartDate}
    End Date: ${formattedEndDate}
    Number of Nights: ${numOfNights}
    Compound: ${compound}
    Address: ${location}
    Total paid : ${total}
    
   If you need to reschedule or have any questions about your appointment, do not hesitate to contact us at 01023100148 or visit our website at www.luxuryelgounarealestate.com.`
    }
    else {
        let packagesInfo = '';
       
        
        for (let i = 0; i < data.packageNames.length; i++) {
            const packageName = data.packageNames[i];
            const packagePrice = data.packagePrices[i];

            packagesInfo += `
            - Package Name: ${packageName}
            - Package Price: ${packagePrice}`;

        }
     
        message=`
        I hope this message finds you well.
Thank you for your payment! We are pleased to confirm your reservation with Luxury El Gouna Real Estate for the following property:

Property Details:

Apartment Name: ${propertyName}
Location: ${location}
Compound: ${compound}
Start Date: ${formattedStartDate}
End Date: ${formattedEndDate}
Total Price: ${total} EGP

Packages Details:
${packagesInfo}

Total Booked Days Outside Packages: ${numOfNights}
Additional Price for Days Outside Package: ${numOfNights*pricePerDay} EGP
Total Price: ${total} EGP

Your reservation is now confirmed, and we look forward to welcoming you. Should you have any questions or require further assistance, please do not hesitate to contact us at 01023100148 or visit our website at www.luxuryelgounarealestate.com.

Thank you for choosing us!
`     
    }                
   emailjs
                    .send(
                      'service_jvmwwqj', // paste your ServiceID here (you'll get one when your service is created).
                      'template_v39w1xb', // paste your TemplateID here (you'll find it under email templates).
                      {
                        to_name: name,// put your name here.
                        to_email: email,
                        subject:subject,
                        message:message,
    
                  
                      },
                      '7CHJ5K3dA2WKCthuj' //paste your Public Key here. You'll get it in your profile section.
                    )
                }catch(err){
                    console.log("unable to send email: " + err);
                }
               
                
            
            }
let dataSource= [];
let packagesNames ='';
let packagesPrices= '';
    appointments.forEach((appointment)=>{
        packagesNames ='';
        packagesPrices= '';
        if(appointment.packageNames.length >0){
            appointment.packageNames.forEach((packageName)=>{
                packagesNames+=`${packageName},`
            })
            appointment.packagePrices.forEach((packagePrice)=>{
                packagesPrices+=`${packagePrice},`
            })
        }
        packagesNames = packagesNames.slice(0, -1); 
        packagesPrices = packagesPrices.slice(0, -1);
        dataSource.push({
      key: appointment._id,
      image: appointment.propertyId.coverImgUrl,
      propertyName: appointment.propertyId.propertyName,
      propertyCompound: appointment.propertyId.compound,
      from:appointment.startDate,
      to:appointment.endDate,
      clientName: `${appointment.userId.fname} ${appointment.userId.lname}`,
      clientEmail: appointment.userId.email,
      clientPhoneNum:appointment.userId.phoneNum,
      packagesNames: packagesNames? packagesNames:"N/A",
      packagesPrices: packagesPrices? packagesPrices:"N/A",
      pricePerDay:appointment.propertyId.discount ? appointment.propertyId.discount : appointment.propertyId.price,
      nightsOutsidePackage:appointment.nightsOutsidePackages,
      total:appointment.total
        })
     
    })



    // const dataSource = appointments
    // .map(appointment => ({
    //   key: appointment._id,
    //   image: appointment.propertyId.coverImgUrl,
    //   propertyName: appointment.propertyId.propertyName,
    //   propertyCompound: appointment.propertyId.compound,
    //   from:appointment.startDate,
    //   to:appointment.endDate,
    //   clientName: `${appointment.userId.fname} ${appointment.userId.lname}`,
    //   clientEmail: appointment.userId.email,
    //   clientPhoneNum:appointment.userId.phoneNum,
    //   packagesNames: packagesNames,
    //   packagesPrices: packagesPrices,
    //   pricePerDay:appointment.propertyId.discount ? appointment.propertyId.discount : appointment.propertyId.price,
    //   nightsOutsidePackage:appointment.nightsOutsidePackages,
    //   total:appointment.total
  
   
    // }))
 


    const columns = [
        {
            title: "Photo",
            dataIndex: "image",
            render: (text) => <img src={text} alt="Property" style={{ width: 50, height: 50 }} />,
            align: 'center',
        },
        {
            title: "Name",
            dataIndex: "propertyName",
            align: 'center',
        },
        {
            title: "Compound",
            dataIndex: "propertyCompound",
            align: 'center',
        },
        {
            title: "From",
            dataIndex: "from",
            render: (text) => {
                const date = new Date(text);
                return date.toLocaleDateString('en-US'); 
            },
            align: 'center',
        },
        {
            title: "To",
            dataIndex: "to",
            render: (text) => {
                const date = new Date(text);
                return date.toLocaleDateString('en-US'); 
            },
            align: 'center',
        },
        {
            title: "Client Name",
            dataIndex: "clientName",
            align: 'center',
        },
        {
            title: "Client Email",
            dataIndex: "clientEmail",
            align: 'center',
        },
        {
            title: "Client Number",
            dataIndex: "clientPhoneNum",
            align: 'center',
        },
        {
            title: "Package",
            dataIndex: "packagesNames",
            align: 'center',
        },
        {
            title: "Package Price",
            dataIndex: "packagesPrices",
            align: 'center',
        },
        {
            title: "Price Per Day",
            dataIndex: "pricePerDay",
            align: 'center',
        },
        {
            title: "Nights Outside Package",
            dataIndex: "nightsOutsidePackage",
            align: 'center',
        },
        {
            title: "Total",
            dataIndex: "total",
            align: 'center',
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (text, record) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button 
                        type="primary" 
                        onClick={() => handleAccept(record.key)}
                        style={{ marginRight: 8 }}
                    >
                        Accept
                    </Button>
                    <Button 
                        type="danger" 
                        onClick={() => handleReject(record.key)}
                    >
                        Reject
                    </Button>
                </div>
            ),
            align: 'center',
        },
    ];

    return (
        <div className="p-2 md:p-4">
            {/* Table and Button components */}
            <div className="overflow-x-auto">
                <Table
                    rowSelection={{
                        selectedRowKeys,
                        onChange: setSelectedRowKeys,
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    rowKey="_id" 
                    scroll={{ x: 'max-content'  }} 
                />
            </div>
        </div>
    );
    
}
 
export default AppointmentList;