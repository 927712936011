import React, { useEffect, useState ,useContext} from 'react';
import AuthContext from '../store/authContext';
import 'flowbite/dist/flowbite.min.css';
import { useLocation,useNavigate } from 'react-router-dom';
import {message} from 'antd'
import emailjs from '@emailjs/browser';
import Carousel from '../component/carousel/carousel2';
import { useTranslation } from 'react-i18next';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import PhotoGrid from '../component/photoGrid/photoGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FourSquare } from "react-loading-indicators";

import {
  faBed,
  faShower,
  faEye,
  faLocationDot,
  faRuler,
  faUtensils,
  faParking,
  faTshirt,
  faWifi,
  faTv,
  faSnowflake,
  faExclamationTriangle,
  faFire,
  faBold,
  faBowlFood,
  faUserTie,
  faWaterLadder,
  faHotTub,
  faUmbrella,
  faTable,
  faBaseball,
  faHouseFire,
  faMusic,
  faDumbbell,
  faWater,
  faUmbrellaBeach,
  faSwimmer
} from '@fortawesome/free-solid-svg-icons';

const SalePropertyDetailsPage = () => {
  const[isLoading,setIsLoading]=useState(true);
  const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [property, setProperty] = useState({});
    const [services,setServices]=useState([]);
    
    const [t,i18n]= useTranslation("global");
    const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
    // const [errMsg,setErrorMsg] = useState('');
    // const [sucessMsg,setSuccessMsg] = useState('');

    const timeOptions = [
        "08:00 AM", "09:00 AM", "10:00 AM", "11:00 AM", "12:00 PM",
        "01:00 PM", "02:00 PM", "03:00 PM", "04:00 PM", "05:00 PM",
        "06:00 PM", "07:00 PM", "08:00 PM"
    ];
    const iconMapping = {
      faBed: faBed,
      faShower: faShower,
      faEye: faEye,
      faLocationDot: faLocationDot,
      faRuler: faRuler,
      faUtensils: faUtensils,
      faParking: faParking,
      faTshirt: faTshirt,
      faWifi: faWifi,
      faTv: faTv,
      faSnowflake: faSnowflake,
      faExclamationTriangle: faExclamationTriangle,
      faFire: faFire,
      faBold: faBold,
      faBowlFood: faBowlFood,
      faUserTie: faUserTie,
      faWaterLadder: faWaterLadder,
      faHotTub: faHotTub,
      faUmbrella: faUmbrella,
      faTable: faTable,
      faBaseball: faBaseball,
      faHouseFire:faHouseFire,
      faMusic: faMusic,
      faDumbbell: faDumbbell,
      faWater: faWater,
      faUmbrellaBeach: faUmbrellaBeach,
      faSwimmer:faSwimmer
  };
    const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 1; 
const day = today.getDate();

    const defaultDate = {
      year: year,
      month: month,
      day: day,
    };
   
    const [date,setDate]= useState(defaultDate);
   
    const convertToLocalizedDate = (dateObj) => {
      const { day, month, year } = dateObj;
      // const date = new Date(year, month - 1, day+1); 
      const date = new Date(Date.UTC(year, month - 1, day));
     return  date.toISOString();
    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const propertyId = queryParams.get('propertyId');

    useEffect(()=>{
      window.scrollTo(0, 0);
        const fetchPropertyData=async ()=>{
            try{
            
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/property/getPropertyUsingId?propertyId=${propertyId}`);
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json(); 
                
                setProperty(data.property);
                setServices(data.property.services);
             setIsLoading(false);
            }catch(err){
                console.log("error in fetching property data : "+err);
            }
        }
        fetchPropertyData();
    },[]);

    const bookApp = async(e)=>{
        e.preventDefault();
  
            // const date=new Date(e.target.date.value);

            const time = e.target.time.value
            const localizeDate=convertToLocalizedDate(date);
            
            if (date===null || e.target.time.value==="" ){
          
                message.error(t("message.visitDateError"));
            }
            else if(Date.now()>new Date(localizeDate))  {
               
            
                message.error(t("message.validDateError"));
            }
            else{
                let status; 
                let bookedBy;
              
                if (!sessionStorage.getItem('token')) {
                    localStorage.setItem('redirectAfterLogin', window.location.pathname+window.location.search);
                    navigate('/logIn');
                    return;
                }else if (sessionStorage.getItem('role') === 'admin'){
                    status="accepted";
                 
                }else{
                    status="pending";
                }
                
                
                
                const userId=sessionStorage.getItem('userId');
                const appData={
                    userId:userId,
                    startDate:localizeDate,
                    propertyId:property._id,
                    status:status ,
                    bookedBy:sessionStorage.getItem('role'),
                    time:time
                }
                try{
                 
                    const baseUrl = process.env.REACT_APP_BASE_URL;
                    const response = await fetch(`${baseUrl}/appointment/bookSaleApp`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(appData)
                    });
                    if(!response.ok){
                        console.log("error in response");
                    }
                    const resposeData= await response.json();
                 
                    if(resposeData.mesg==="Appointment Booked successfully"){
                        message.success(t("message.appSucess"));
                        
                        setTimeout(()=>{
                            if(sessionStorage.getItem('role')==='user'){
                            sendEmail();
                            }
                            navigate("/");
                        },2000);
                    }
                    else 
                        message.error(t("message.appAlreadyReserved"));
                }catch(err){
                    console.log("error in adding appointment : "+err)
                }
    
            }
            const sendEmail = async()=>{
                try{
                    const baseUrl = process.env.REACT_APP_BASE_URL;
                    const response = await fetch(`${baseUrl}/getUserData?userId=${sessionStorage.getItem('userId')}`);
                    
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json(); 
                    const email = data.userData.email;
                    const name = data.userData.name;
                    const subject = " Appointment Confirmation from elGonaLuxury";
                    const message =`

Thank you for scheduling an appointment with elGonaLuxury.

We are currently processing your appointment request and will send you a confirmation email shortly. If we require any additional information or if there are any updates regarding your appointment, we will keep you informed.

If you have any questions or need further assistance, please feel free to contact us at 01023100148 or visit our website at www.elGonaLuxury.com.`;
              
                    emailjs
                    .send(
                      'service_jvmwwqj', // paste your ServiceID here (you'll get one when your service is created).
                      'template_v39w1xb', // paste your TemplateID here (you'll find it under email templates).
                      {
                        to_name: name,// put your name here.
                        to_email: email,
                        subject:subject,
                        message:message
                      
                  
                      },
                      '7CHJ5K3dA2WKCthuj' //paste your Public Key here. You'll get it in your profile section.
                    )
                }catch(err){

                }
               
                
            
            }
    
    
        }
        const displayDescription = () => {
          const { description, descriptionAR, descriptionGER } = property;
          
          // Check if all descriptions are empty
          const emptyDescriptions = !descriptionAR && !descriptionGER;
        
          if (emptyDescriptions) {
            return i18n.language === 'en' ? description : description; // English by default
          }
        
          switch (i18n.language) {
            case 'ar':
              return (
          <div
            dangerouslySetInnerHTML={{
              __html: (descriptionAR || description).replace(/\n/g, '<br />')
            }}
          />
        );
            case 'de':
              return (
              <div
              dangerouslySetInnerHTML={{
                __html: (descriptionGER || description).replace(/\n/g, '<br />')
              }}
            />
          );
            default:
              return (
              <div
              dangerouslySetInnerHTML={{
                __html: (description).replace(/\n/g, '<br />')
              }}
            />
          );
          }
        };
        return (
          <>
                  {isLoading ?(
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <FourSquare color="#FFDDB9" size="medium" text="" textColor="" />
      </div>
    ):(
            <section className="py-0 pageDetailsCustumMargin">
              <h2 className='text-center'>{property.propertyName}</h2>
              <div className='d-flex justify-content-center' style={{ maxWidth: "1120px", margin: "0 auto" }}>
                <div className="d-none d-md-block"> {/* PhotoGrid visible on medium and up */}
                  <PhotoGrid imgUrls={property.imagesUrl} />
                </div>
                <div className="d-block d-md-none"> {/* Carousel visible only on mobile */}
                  <div className="container px-4 px-lg-5 my-5">
                    <div className="row gx-4 gx-lg-5 align-items-start">
                      <div className="col-md-6">
                        <Carousel imgUrls={property.imagesUrl} />
                      </div>
                      <div className="col-md-6">
                        <h1 className="display-5 fw-bolder mb-3 text-center">{property.propertyName}</h1>
                        <div className="flex flex-wrap justify-center">
                          {[{ icon: faBed, label: property.numBeds },
                            { icon: faShower, label: property.numShower },
                            { icon: faLocationDot, label: property.compound },
                            { icon: faEye, label: property.view }
                          ]
                            .map((badge, index) => (
                              <span key={index} className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
                                <FontAwesomeIcon className='text-center mr-2' size='lg' icon={badge.icon} color="orange" /> {badge.label}
                              </span>
                          ))}
                        </div>
                        <p className="lead mt-3" style={{ direction }}>
                          {displayDescription()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 flex flex-wrap justify-content-center d-none d-md-flex">
      <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
        <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faBed} color="orange" /> {property.numBeds}
      </span>
      <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
        <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faShower} color="orange" /> {property.numShower}
      </span>
      <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
        <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLocationDot} color="orange" /> {property.compound}
      </span>
      <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
        <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faEye} color="orange" /> {property.view}
      </span>
    
    </div>
              <div className="flex flex-wrap justify-content-center mt-0 mt-md-5">
                <hr className="mx-auto mt-0 mb-5 mt-md-2" style={{ width: "50%" }} />
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div className='WhatWeOffer'>
           <div className="container mt-4 col-12 col-md-6">
        <h3 className="text-center">{t("whatThisPlaceOffer")}</h3>
        <div className="row mt-5 justify-content-start">
          {services.map((service, index) => (
            <div className="col-6 d-flex align-items-center mb-3" key={index}> {/* Adjusted mb-2 to mb-3 */}
              {iconMapping[service.icon] ? (
                <FontAwesomeIcon icon={iconMapping[service.icon]} size='lg' className="mr-2" />
              ) : (
                <span className="text-danger">Icon not found</span>
              )}
              <span>
              {
                  i18n.language === 'ar' ?  service.arName:
                  i18n.language === 'de' ? service.deName :
                  service.enName
                }
                </span>
            </div>
          ))}
        </div>
      </div>
      
      
                  </div>
                </div>
      
                <div style={{
               margin: "0px auto",
               display: "flex",
              //  flex-direction: row
               alignItems: "center",
               alignContent: "space-around",
               justifyContent: "space-around",
                flexWrap: "wrap",
                 gap: "5em"
                }}>
                 
                 <div className="d-none d-md-block" style={{ width: "50em",height:"30em", padding: "0 20px" }}>
        <h4 className='text-md font-medium' style={{ direction }}>{t("description")}</h4>
        <p className="lead mt-3" style={{ direction }}>
          {displayDescription()}
        </p>
      </div>
      
      
                  <form onSubmit={bookApp}>
                    <div className="flex flex-col space-y-4 mt-3">
                      <div className="flex flex-col w-full">
                        <label htmlFor="dateFrom" className="text-sm font-medium text-center mb-2">{t("ReserveWithUs")}</label>
                    
                        <Calendar
                      value={date}
                      onChange={setDate}
                      shouldHighlightWeekends
                      renderFooter={() => (
                        <div className="d-flex justify-content-center pb-3">
                        <select
                          id="time"
                          className="p-2 border rounded-md w-50"
                          defaultValue=""
                        >
                          <option value="" disabled>{t("saleProp.choosetime")}</option>
                          {timeOptions.map((time, index) => (
                            <option key={index} value={time}>{time}</option>
                          ))}
                        </select>
                      </div>
                      
                      )}
                    />
                      
                      </div>
      
                      <p className="lead mt-3" style={{ direction }}>
                        <span style={{ fontWeight: 'bold', direction: 'inherit' }}>
                          {t("saleProp.price")}
                        </span>
                        {property.discount ? (
                          <div style={{ display: 'inline-flex', flexDirection: direction === 'rtl' ? 'row-reverse' : 'row', alignItems: 'center' }}>
                            <span style={{ color: 'red', fontWeight: 'bold', margin: direction === 'rtl' ? '0 10px 0 0' : '0 0 0 10px' }}>
                              {property.discount} {t("curr")}
                            </span>
                            <span style={{ textDecoration: 'line-through', margin: direction === 'rtl' ? '0 10px 0 0' : '0 0 0 10px' }}>
                              {property.price}  {t("curr")}
                            </span>
                          </div>
                        ) : (
                          <span>{property.price}  {t("curr")}</span>
                        )}
                      </p>
      
                      <button className="px-6 mt-3 py-2 text-white bg-black rounded-md hover:bg-blue-600 w-full">
                        {t("reservBtn")}
                      </button>
      
                    </div>
                  </form>
                  </div>
                </div>
          
            </section>
             )
            }
          </>
          
        );
          

}
 
export default SalePropertyDetailsPage;