import React, { useEffect, useState } from 'react';
import Product from '../component/cards/productCard';
import { useTranslation } from 'react-i18next';
import { Select } from "antd";
import { FourSquare } from "react-loading-indicators";
const AllPropertiesPage = () => {
    const [isLoading,setIsLoading]=useState(true);
    const [properties, setProperties] = useState([]);
    const [compoundNames, setCompoundNames] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [selectedCompound, setSelectedCompound] = useState('All');
    const [selectedType, setSelectedType] = useState('All');
    const [t] = useTranslation("global");

    // Construct unique compound names
    const constructCompoundNames = () => {
        const uniqueCompoundNames = new Set(properties.map(property => property.compound));
        setCompoundNames(Array.from(uniqueCompoundNames));
    };

    // Fetch properties and set state
    useEffect(() => {
        const fetchProperties = async () => {
            window.scrollTo(0, 0);
            const baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${baseUrl}/property/getProperties`);
            
            const data = await response.json();
            if (!response.ok) {
                throw new Error("error in getting properties");
            }
            
            setProperties(data.properties);
            setFilteredProperties(data.properties);
            setIsLoading(false);
        };
        fetchProperties();
    }, []);

    // Update compound names when properties change
    useEffect(() => {
        constructCompoundNames();
    }, [properties]);

    // Apply filters based on selected values
    useEffect(() => {
        let updatedProperties = properties;
        
        if (selectedCompound !== 'All') {
            updatedProperties = updatedProperties.filter(property => property.compound === selectedCompound);
        }
        
        if (selectedType !== 'All') {
            updatedProperties = updatedProperties.filter(property => property.propertyType === selectedType);
        }
        
        setFilteredProperties(updatedProperties);
    }, [selectedCompound, selectedType, properties]);

    const handleFilterChange = (value) => {
        setSelectedCompound(value);
    };

    const handleFilterTypeChange = (value) => {
        setSelectedType(value);
    };

    return (
        <>
           {isLoading ?(
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <FourSquare color="#FFDDB9" size="medium" text="" textColor="" />
      </div>
    ):(
        <div className="customMargin">
            <Select
                style={{ width: 200, marginLeft: '16px' }}
                onChange={handleFilterChange}
                placeholder={t("filterProp")}
            >
                <Select.Option key="all" value="All">
                    All
                </Select.Option>
                {compoundNames.map((compound, index) => (
                    <Select.Option key={index} value={compound}>
                        {compound}
                    </Select.Option>
                ))}
            </Select>

            <Select
                style={{ width: 200, marginLeft: '16px' }}
                onChange={handleFilterTypeChange}
                placeholder={t("propType")}
            >
                <Select.Option key="all" value="All">
                    All
                </Select.Option>
                <Select.Option key="rent" value="rent">
                    Rent
                </Select.Option>
                <Select.Option key="sale" value="sale">
                    Sale
                </Select.Option>
            </Select>
            
            <div className='d-flex flex-wrap justify-center md:ml-0 md:mr-0'>
                {filteredProperties.map((property) => (
                    <div style={{ margin: '1em' }} >
                    <Product
                 
                    
                        key={property._id}
                        property={property}
                    />
                    </div>
                ))}
            </div>
            
        </div>
    )
    }
        </>
    );
}

export default AllPropertiesPage;
