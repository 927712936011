import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';
import AuthProvider from './store/authProvider';
import Modal from 'react-modal';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import global_en from './translation/eng/global.json';
import global_ar from './translation/ar/global.json';
import global_gr from './translation/ger/global.json';

// Initialize i18next
i18next
  .use(LanguageDetector) // Add the language detector plugin
  .use(initReactI18next) // Pass i18n instance to react-i18next
  .init({
    interpolation: { escapeValue: false }, // React already handles escaping
    fallbackLng: 'en', // Default language if detection fails
    detection: {
      order: ['localStorage', 'cookie', 'navigator'], // Detection order
      caches: ['localStorage'], // Cache the language in local storage
    },
    resources: {
      en: {
        global: global_en,
      },
      ar: {
        global: global_ar,
      },
      de: {
        global: global_gr,
      },
    },
  });

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider> 
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </AuthProvider> 
);
