import React,{useEffect, useState} from 'react';
import { useNavigate ,Link,useLocation } from 'react-router-dom';

import Header from '../component/home/header';
import AboutUs from '../component/home/aboutUs';
import Statistic from '../component/statistics/statistics';
import ComCard from './../component/cards/comCard';
import ContactUsForm from '../component/cards/contactUs/contactUsForm';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey  } from '@fortawesome/free-solid-svg-icons';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faSmile  } from '@fortawesome/free-solid-svg-icons';
import {faArrowRight  } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';


import Product from './../component/cards/productCard';

const HomePage = () => {
    const [featuredProperties,setFeaturedProperties]=useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [activeLink, setActiveLink] = useState('allProperties');
    const [rentCount , setRentCount] = useState('');
    const [saleCount, setSaleCount] = useState('');
    const [statisfiedCustCount, setStatisfiedCustCount] = useState('');
    const navigate=useNavigate();
    const location = useLocation();
    const [t]= useTranslation("global");
  useEffect(() => {
    // Extract the hash from the URL
    const hash = location.hash;

    // If there's a hash, scroll to the corresponding section
    if (hash) {
      const targetElement = document.querySelector(hash);

      if (targetElement) {
        // Adjust the scroll position if needed
        window.scrollTo({
          top: targetElement.offsetTop - 160, // Adjust the offset as needed
          behavior: 'smooth'
        });
      }
    }
  }, [location]);
useEffect(() => {
  // window.scrollTo(0, 0);
  const fetchPropertiesCount=async()=>{
    try{
      
      const baseUrl = process.env.REACT_APP_BASE_URL;
    
      const response = await fetch(`${baseUrl}/property/getPropStatistics`);
      const data = await response.json();
      if (!response.ok){
          throw new Error("error in geting FeaturedProperties");
      }
      setRentCount(data.rentCount);
      setSaleCount(data.saleCount);

    }catch(err){
      console.log("error in getting properties count : "+err);
    }
  }
  const fetchStatisfiedCust=async()=>{
    try{
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${baseUrl}/appointment/getAppStatistics`);
      const data = await response.json();
      if (!response.ok){
          throw new Error("error in geting statisfied customers");
      }
     
      setStatisfiedCustCount(data.statisfiedCust);
   

    }catch(err){
      console.log("error in getting statisfied customers count : "+err);
    }
  }
const fetchPropeties= async()=>{
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const response = await fetch(`${baseUrl}/property/getFeaturedProperties`);
    const data = await response.json();
    if (!response.ok){
        throw new Error("error in geting FeaturedProperties");
    }
    setFeaturedProperties(data.featuredProperties);
    setFilteredProperties(data.featuredProperties);

};
fetchPropeties();
fetchPropertiesCount();
fetchStatisfiedCust();
},[]);

const handleFilter = (type) => {

    setActiveLink(type);
    if(type==='allProperties'){
        setFilteredProperties(featuredProperties);
    }
    else{
    setFilteredProperties(featuredProperties.filter(property => property.propertyType === type));
    }
  };

    return (  
        <>
    <Header/>
    <AboutUs/>
    <div id='services' className='homePageCardsSection'>
<ComCard
  title={t("com.com1Title")} 
  description={t("com.com1Body")}
  location="/rentProperties"
/>
<ComCard
 title={t("com.com2Title")} 
 description={t("com.com2Body")}
location="/saleProperties"
/>
</div>
    <div className='statisticsBGColor d-flex justify-content-around pt-4 pb-5 flex-wrap'>
    <Statistic
    numOfProperty={saleCount+'000000'}
    description={t("stat.sale")}
    icon={faHouse}
    />
    <Statistic
       numOfProperty={rentCount+'000000'} //'450000000'
       description={t("stat.rent")}
       icon={faKey}
       />
   <Statistic
       numOfProperty= {statisfiedCustCount+'000000'}// '5000000000'
       description={t("stat.client")}
       icon={faSmile}
       />
     

    </div>
    
    <div className='FeaturedTitle'>
    <h1 className='text-center'>{t("feturedProp.header")}</h1>

   <Link to='/AllProperties' className='exploreLink'>{t("feturedProp.expBtn")} <FontAwesomeIcon  className='text-center ml-2 mt-2' icon={faArrowRight}  size='lg' color="#fd650b" /></Link>
    </div>
    <div className='rentSaleNav'>
        <a
         
          onClick={() => handleFilter('allProperties')}
          style={{ color: activeLink === 'allProperties' ? '#fd650b' : 'black' }}
        >
         {t("feturedProp.allProp")}
        </a>
        <a
         
          onClick={() => handleFilter('rent')}
          style={{ color: activeLink === 'rent' ? '#fd650b' : 'black' }}
        >
        {t("feturedProp.rent")}
        </a>
        <a
         
         onClick={() => handleFilter('sale')}
         style={{ color: activeLink === 'sale' ? '#fd650b' : 'black' }}
       >
          {t("feturedProp.sale")}
       </a>
      </div>

      <div className='d-flex flex-wrap justify-center md:ml-0 md:mr-0'>
     
     {filteredProperties.map((property) => (
        <div style={{ margin: '1em' }} >
         <Product
           key={property._id}
           property={property}
         />
        </div>
     ))}
       
 </div>
 
      <div id='contactUs'>

<ContactUsForm />
      </div>

    </>
    );
}
 
export default HomePage;
